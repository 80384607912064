import { Route } from "found";


import AdoptedSystemDocumentTemplatesQuery from "./AdoptedSystemDocumentTemplatesQuery";
import LocalDocumentsForTemplatePage, {
  UnpublishedDocumentsForTemplatePage
} from "./LocalDocumentsForTemplate";
import LocalDocumentsForTemplatePageQuery from "./LocalDocumentsForTemplateQuery";
import PublishedTemplatesPage, {
  UnpublishedTemplatesPage
} from "./PublishedTemplatesPage";
import TemplateManagementPage from "./TemplateManagementPage";
import TemplateManagementPageQuery from "./TemplateManagementPageQuery";
import UnpublishedAdoptedSystemDocumentTemplatesQuery from "./UnpublishedAdoptedSystemDocumentTemplatesQuery";
import UnpublishedDocumentsForTemplatePageQuery from "./UnpublishedDocumentsForTemplateQuery";
import ViewTemplatePage from "./view_template/ViewTemplatePage";
import ViewTemplatePageQuery from "./view_template/ViewTemplateQuery";

import { PageWrapper } from "pstat-anywhere/utils/pageWrappers";
import TemplateReviewPage from "pstat-anywhere/components/system_document_templates/TemplateReviewPage";
import { HITS_PER_PAGE } from "pstat-anywhere/components/pages/reports";
import ViewPolicyQuery from "pstat-anywhere/components/document_control/view_policy/ViewPolicyQuery";

const getReportParams = location => ({
  sort: location.query.sort,
  first: location.query.rowsPerPage || HITS_PER_PAGE,
  after: location.query.after
});

const routes = (
  <Route path="template">
    <Route
      path="/manage"
      data={{ routeName: "manage_templates" }}
      query={TemplateManagementPageQuery}
      prepareVariables={(params, { location }) => ({
        ...params,
        ...getReportParams(location)
      })}
      render={({ props }) => (
        <PageWrapper
          component={TemplateManagementPage}
          queryName="systemDocumentTemplates"
          titleKey="systemDocumentTemplates.header"
          {...props}
        />
      )}
    />
    <Route
      path="/:pk(\d+)"
      data={{ routeName: "view_template" }}
      query={ViewTemplatePageQuery}
      render={({ props }) => <ViewTemplatePage {...props} />}
    />
    <Route
      path="/published"
      data={{ routeName: "published_templates" }}
      query={AdoptedSystemDocumentTemplatesQuery}
      prepareVariables={(params, { location }) => ({
        ...params,
        ...getReportParams(location)
      })}
      render={({ props }) => (
        <PageWrapper
          component={PublishedTemplatesPage}
          queryName="adoptedSystemDocumentTemplates"
          titleKey="systemDocumentTemplates.published.header"
          {...props}
        />
      )}
    />
    <Route
      path="/unpublished"
      data={{ routeName: "unpublished_templates" }}
      query={UnpublishedAdoptedSystemDocumentTemplatesQuery}
      prepareVariables={(params, { location }) => ({
        ...params,
        ...getReportParams(location)
      })}
      render={({ props }) => (
        <PageWrapper
          component={UnpublishedTemplatesPage}
          queryName="unpublishedAdoptedSystemDocumentTemplates"
          titleKey="systemDocumentTemplates.published.header"
          {...props}
        />
      )}
    />
    <Route
      path="/:pk(\d+)/subscribed"
      data={{ routeName: "documents_for_template_subscribed" }}
      query={LocalDocumentsForTemplatePageQuery}
      prepareVariables={(params, { location }) => ({
        ...params,
        ...getReportParams(location)
      })}
      render={({ props }) => (
        <PageWrapper
          component={LocalDocumentsForTemplatePage}
          queryName="blocSystemDocumentTemplatesDocuments"
          titleKey="systemDocumentTemplates.localDocumentsForTemplate.title"
          documentName={
            props?.blocSystemDocumentTemplatesDocuments
              ?.systemDocumentTemplateName
          }
          {...props}
        />
      )}
    />
    <Route
      path="/:pk(\d+)/unsubscribed"
      data={{ routeName: "documents_for_template_unsubscribed" }}
      query={UnpublishedDocumentsForTemplatePageQuery}
      prepareVariables={(params, { location }) => ({
        ...params,
        ...getReportParams(location)
      })}
      render={({ props }) => (
        <PageWrapper
          component={UnpublishedDocumentsForTemplatePage}
          queryName="hiddenSystemDocumentTemplatesDocuments"
          titleKey="systemDocumentTemplates.localDocumentsForTemplate.title"
          documentName={
            props?.hiddenSystemDocumentTemplatesDocuments
              ?.systemDocumentTemplateName
          }
          {...props}
        />
      )}
    />
    <Route
      path="/:documentPk(\d+)/review"
      data={{ routeName: "review_template" }}
      query={ViewPolicyQuery}
      // TODO: remove this latest variable after switching to review query
      prepareVariables={params => ({
        ...params,
        latest: false
      })}
      render={({ props }) => (
        <PageWrapper
          component={TemplateReviewPage}
          queryName="documentView"
          titleKey="systemDocumentTemplates.reviewTemplate.title"
          documentName={props?.documentView?.document?.name}
          showLoading={!props?.documentView}
          {...props}
        />
      )}
    />
  </Route>
);

export default routes;
