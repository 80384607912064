import graphql from "babel-plugin-relay/macro";

export const ViewPolicyQuery = graphql`
  query ViewPolicyQuery(
    $documentPk: Int!
    $showChanges: Boolean
    $latest: Boolean
    $ignoreLatestRedirect: Boolean
  ) {
    documentView(
      pk: $documentPk
      showChanges: $showChanges
      latest: $latest
      ignoreLatestRedirect: $ignoreLatestRedirect
    ) {
      document {
        ...commentForm_document
        ...SecondaryNav_document
        id
        pk
        name
        html
        author {
          pk
          userDisplay
          firstName
          lastName
          profile {
            title
          }
        }
        category {
          pk
          name
        }
        bloc {
          id
          pk
          name
          tenants(sort: "name") {
            pk
            name
          }
        }
        parent {
          id
        }
        isAForm
        status
        restricted
        effectiveDate(formatted: true)
        approvalDate(formatted: true)
        expirationDate(formatted: true)
        revisionDate(formatted: true)
        revisionDates
        statusChangedDate
        originationDate(formatted: true)
        legacyEffectiveDate(formatted: true)
        created
        modified
        approvable
        headerImageUrl
        rssFeedUrl
        rawEffectiveDate: effectiveDate
        effectiveDateTimedelta

        actions(isEditPage: false) {
          id
          pk
          actionType
          actionDate
          comment
          canEdit
          isExternal
          user {
            id
            userDisplay(userDisplayType: FORWARD)
          }
        }
        attachments {
          title
          fileUrl
        }
        tags {
          pk
          name
        }
        approvalWorkflow {
          name
          currentStep {
            id
          }
          steps {
            id
            name
            approvers {
              firstName
              lastName
              isDocumentOwner
              hasApproved
            }
          }
        }
        workflowTemplate {
          name
        }
        approvalSignatures {
          approver {
            firstName
            lastName
            title
          }
          proxySigner {
            firstName
            lastName
            title
          }
          approvalStep {
            name
          }
          approvedDate(formatted: true)
          required
        }
        pastApprovalSignatures {
          approver {
            firstName
            lastName
            title
          }
          proxySigner {
            firstName
            lastName
            title
          }
          approvalStep {
            name
          }
          approvedDate(formatted: true)
          required
        }
        regulations {
          pk
          name
        }
        onesourceDocuments {
          pk
          name
          viewUrl
          revisionInformation
        }
        epAssociationDocuments {
          pk
        }
        draftVersionLink
        pendingVersionLink
        scheduledVersionLink
        isLippincottDocument
        linePk
        documentsOfTemplate {
          documents {
            pk
            bloc {
              pk
              name
            }
          }
          systemDocumentTemplate {
            bloc {
              name
            }
            document {
              pk
            }
          }
        }
        isTemplate
      }
      canCreateReadReceipt
      showChanges
      showChangesTaskId
      documentAccessTokens {
        ...TokenAccess_tokens
      }
      policyActions
      guestAccessLink
      error {
        statusCode
        message
      }
      tenantsToRedirect {
        name
        url
      }
      redirectUrl
      canEditDocument
      showTimeline
      lippincottEnabled
      documentSave {
        pk
      }
    }
  }
`;

export default ViewPolicyQuery;
