import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import system from "system-components";

import theme from "../../../themes/policystat/theme";

import MessageIcon from "./MessageIcons";

export const MESSAGE_TYPES = {
  SUCCESS: "SUCCESS",
  INFO: "INFO",
  ERROR: "ERROR",
  WARNING: "WARNING"
};

type Props = {
  type?: $Values<typeof MESSAGE_TYPES>,
  content: string | HTMLElement
};

const StyledMessageContainer = system({
  is: "section",
  width: "320px",
  minHeight: "64px",
  my: 2,
  pt: 3,
  pb: 2,
  pr: 4,
  pl: 3,
  display: "flex",
  flexWrap: "nowrap",
  color: theme.colors.nav[100],
  fontFamily: theme.fontFamily
}).extend`
  border-radius: ${theme.radii[1]}px;
  background-color: ${({ type }: Props) => {
    if (type === MESSAGE_TYPES.SUCCESS) {
      return theme.colors.messages.success;
    } else if (type === MESSAGE_TYPES.WARNING) {
      return theme.colors.messages.warning;
    } else if (type === MESSAGE_TYPES.ERROR) {
      return theme.colors.messages.error;
    } else {
      return theme.colors.secondary[0];
    }
  }}
`;

export default function Message({ type, content, render, iconType }: Props) {
  return (
    <StyledMessageContainer type={type}>
      <MessageIcon type={iconType || type} />
      {render ? (
        render()
      ) : (
        <HTMLEllipsis
          unsafeHTML={content}
          maxLine="2"
          ellipsis="..."
          basedOn="letters"
        />
      )}
    </StyledMessageContainer>
  );
}
