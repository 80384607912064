import { tint } from "polished";

const bases = {
  nav: "#182d4a",
  primary: "#fdba40",
  secondary: "#315594",
  error: "#d61600",
  warning: "#c06d00",
  success: "#36a13d",
  maintenance: "#245699",
  training: "#fdba40"
};

const colors = {
  nav: {
    0: bases.nav,
    10: tint(0.9, bases.nav),
    25: tint(0.75, bases.nav),
    55: tint(0.45, bases.nav),
    80: tint(0.2, bases.nav),
    90: tint(0.1, bases.nav),
    95: tint(0.05, bases.nav),
    100: tint(0, bases.nav)
  },
  primary: {
    0: bases.primary,
    40: tint(0.6, bases.primary),
    80: tint(0.2, bases.primary)
  },
  secondary: {
    0: bases.secondary,
    15: tint(0.85, bases.secondary),
    80: tint(0.2, bases.secondary)
  },
  error: {
    0: bases.error,
    15: tint(0.85, bases.error),
    80: tint(0.2, bases.error)
  },
  messages: {
    error: bases.error,
    warning: bases.warning,
    success: bases.success
  },
  banners: {
    error: {
      light: "#FBEAE5",
      common: "#FEAD9A",
      dark: "#DE3618"
    },
    info: {
      light: "#E5ECF5",
      common: tint(0.75, bases.secondary),
      dark: bases.secondary
    },
    success: {
      light: "#F1F8EF",
      common: "#BBE5B3",
      dark: "#30A237"
    },
    warning: {
      light: tint(0.2, bases.warning),
      common: tint(0.75, bases.warning),
      dark: bases.warning
    },
    maintenance: {
      light: tint(0.2, bases.maintenance),
      common: tint(0.75, bases.maintenance),
      dark: bases.maintenance
    },
    training: {
      light: tint(0.2, bases.training),
      common: tint(0.75, bases.training),
      dark: bases.training
    }
  },
  switch: {
    help: "#009B00",
    background: "#006BB4"
  },
  disabled: {
    header: "#8796A5",
    tag: "#909EAC"
  },
  buckets: {
    ok: "#008a23",
    warning: "#B55B00",
    danger: "#BB0000",
    neutral: "#102E4C"
  },
  misc: {
    "selected-pagination-text": "#000710",
    "show-changes-text": "#6e7784"
  }
};

export default colors;
