import graphql from "babel-plugin-relay/macro";
import { withRouter } from "found";
import PropTypes from "prop-types";
import { Component, Fragment, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { withLabelContext } from "../../context_providers/LabelContext";
import { withViewer } from "../../context_providers/ViewerContext";
import theme from "../../themes/policystat/theme";
import { getRootUrl } from "../../utils/urls";
import LogoutMenuLink from "../authentication/LogoutLink";
import ProxyLoginMenuLink from "../authentication/ProxyLogin";
import ProxyLoginReverseMenuLink from "../authentication/ProxyLoginReverse";
import LanguageSelector from "../design-system/LanguageSelector";
import { canAccessVergeAssociatedDocumentsReport } from "../document_reports/verge_standards_associated/VergeAssociatedDocumentsReport";
import HamburgerHeader from "../partials/HamburgerHeader";
import { Menu, MenuDivider, MenuText } from "../partials/Menu";
import MenuLink, { MenuLabel } from "../partials/MenuLink";
import { SubMenu, SubMenuDivider, SubMenuSection } from "../partials/SubMenu";
import { Toolbar } from "../partials/Toolbar";
import VersionSwitcher from "../partials/VersionSwitcher";

import { Text } from "pstat-design-system/text";
import { AccessibleTenantsDropdownItems } from "pstat-design-system/SiteSelector";
import titleCase from "pstat-anywhere/utils/titleCase";
import { canViewInDevelopmentWork } from "pstat-anywhere/utils/permissions";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import MenuButton from "pstat-anywhere/components/design-system/shared/MenuButton";
import LoginModal from "pstat-anywhere/components/authentication/LoginModal";

const HomeMenuLink = styled(MenuLink)`
  height: 51px;
  @media (min-width: ${theme.breakpoints[1]}) {
    height: 100%;
  }
`;

const Home = ({ closeMenu, isHomePage }) => {
  const { t } = useTranslation();
  if (isHomePage) {
    return (
      <MenuButton color="nav.100" px={2} active={true}>
        {t("nav.primary.homeLink")}
      </MenuButton>
    );
  } else {
    return (
      <HomeMenuLink
        onClick={closeMenu}
        mainMenu
        exact={true}
        to={{
          pathname: `${getRootUrl()}/`
        }}
      >
        {t("nav.primary.homeLink")}
      </HomeMenuLink>
    );
  }
};

const _Policies = ({
  documentTemplates,
  expandedMenu,
  closeMenu,
  labels: {
    documentLabelPlural,
    categoryLabel,
    authorLabel,
    referencesLabelPlural,
    documentLabel
  },
  enableEditWithWord,
  match,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const addingSearchQuery = defaultParams => {
    const { q } = match.location?.query || {};
    if (q) {
      return { ...defaultParams, q };
    }
    return defaultParams;
  };

  return (
    <SubMenu
      name={documentLabelPlural}
      expanded={expandedMenu === documentLabelPlural}
      closeMenu={closeMenu}
      {...otherProps}
    >
      <MenuLink
        to={{
          pathname: `${getRootUrl()}/search/`,
          query: addingSearchQuery({ titleOnly: true, sort: "name" })
        }}
        onClick={closeMenu}
      >
        {t("nav.primary.policies.byTitle", { documentLabelPlural })}
      </MenuLink>
      <MenuLink
        to={{
          pathname: `${getRootUrl()}/search/`,
          query: addingSearchQuery({ expanded: "categories", sort: "category" })
        }}
        onClick={closeMenu}
      >
        {t("nav.primary.policies.byCategories", {
          documentLabelPlural,
          categoryLabel
        })}
      </MenuLink>
      <MenuLink
        to={{
          pathname: `${getRootUrl()}/search/`,
          query: addingSearchQuery({ expanded: "authors", sort: "name" })
        }}
        onClick={closeMenu}
      >
        {t("nav.primary.policies.byAuthor", {
          documentLabelPlural,
          authorLabel
        })}
      </MenuLink>
      <MenuLink
        to={{
          pathname: `${getRootUrl()}/search/`,
          query: addingSearchQuery({ expanded: "tags", sort: "name" })
        }}
        onClick={closeMenu}
      >
        {t("nav.primary.policies.byReferences", {
          documentLabelPlural,
          referencesLabelPlural
        })}
      </MenuLink>
      {!otherProps.viewer.isGuest &&
        !otherProps.tenant.settings.enableAllBlocs && (
          <Fragment>
            <SubMenuDivider />
            <MenuLink
              id="drafts_report"
              to={{ pathname: `${getRootUrl()}/draft/mine/` }}
              onClick={closeMenu}
            >
              {t("nav.primary.policies.drafts")}
            </MenuLink>
            <MenuLink
              id="my_policies"
              to={{
                pathname: `${getRootUrl()}/search/`,
                query: addingSearchQuery({ author: otherProps.viewer.pk })
              }}
              onClick={closeMenu}
            >
              {t("nav.primary.policies.my", { documentLabelPlural })}
            </MenuLink>
            {documentTemplates && (
              <Fragment>
                <SubMenuDivider />
                <MenuLabel color={["inherit", "nav.25"]} fontWeight="medium">
                  {t("nav.primary.policies.newDraftForTemplate")}
                </MenuLabel>
                <SubMenuSection className="document_templates">
                  {documentTemplates.map(template => (
                    <MenuLink
                      to={{
                        pathname: `${getRootUrl()}/policy/create/new/`,
                        query: { template: template.pk }
                      }}
                      onClick={closeMenu}
                      key={template.pk}
                    >
                      {template.name}
                    </MenuLink>
                  ))}
                  {documentTemplates.length === 0 && (
                    <MenuLink
                      className="default_template"
                      to={{ pathname: `${getRootUrl()}/policy/create/new/` }}
                      onClick={closeMenu}
                    >
                      {t("nav.primary.policies.defaultTemplate")}
                    </MenuLink>
                  )}
                </SubMenuSection>
              </Fragment>
            )}
            {enableEditWithWord &&
              otherProps.viewer.userAuth.canCreateDocuments && (
                <Fragment>
                  <SubMenuDivider />
                  <MenuLink
                    id="import_policy"
                    onClick={closeMenu}
                    to={{ pathname: `${getRootUrl()}/build/` }}
                  >
                    {t("nav.primary.policies.import", { documentLabel })}
                  </MenuLink>
                </Fragment>
              )}
          </Fragment>
        )}
    </SubMenu>
  );
};
const Policies = withViewer(withLabelContext(withRouter(_Policies)));

const _Reports = ({ expandedMenu, closeMenu, labels, ...otherProps }) => {
  const { t } = useTranslation();
  const { documentLabelPlural } = labels;
  return (
    <SubMenu
      name={t("nav.primary.reports.menuName")}
      expanded={expandedMenu === t("nav.primary.reports.menuName")}
      {...otherProps}
      closeMenu={closeMenu}
    >
      {!otherProps.viewer.isGuest && (
        <Fragment>
          <MenuLink
            onClick={closeMenu}
            to={`${getRootUrl()}/policy_reports/awaiting_activation/pending/all`}
          >
            {t("nav.primary.reports.pendingApprovals")}
          </MenuLink>
          <MenuLink
            onClick={closeMenu}
            to={`${getRootUrl()}/policy_reports/expiring/mine`}
          >
            {t("nav.primary.reports.dueForReview")}
          </MenuLink>
          {!otherProps.tenant.settings.enableAllBlocs && (
            <MenuLink
              id="awaiting_acks_report"
              onClick={closeMenu}
              to={`${getRootUrl()}/acknowledgment/mine/`}
            >
              {t("nav.primary.reports.awaitingAcknowledgments")}
            </MenuLink>
          )}
        </Fragment>
      )}
      <MenuLink
        to={`${getRootUrl()}/policy_reports/revised/recent`}
        onClick={closeMenu}
      >
        {t("home.tables.revised.title")}
      </MenuLink>
      {!otherProps.viewer.isGuest && (
        <Fragment>
          {otherProps.scheduledEffectiveDateEnabled && (
            <MenuLink
              id="scheduled_report"
              onClick={closeMenu}
              to={`${getRootUrl()}/policy_reports/awaiting_activation/scheduled`}
            >
              {t("nav.primary.reports.scheduled")}
            </MenuLink>
          )}
          <MenuLink
            onClick={closeMenu}
            to={`${getRootUrl()}/policy_reports/duplicates`}
          >
            {t("nav.primary.reports.duplicates")}
          </MenuLink>
        </Fragment>
      )}
      {!otherProps.viewer.isGuest &&
        canAccessVergeAssociatedDocumentsReport(otherProps.tenant.settings) && (
          <MenuLink
            id="verge_associated_documents_report"
            onClick={closeMenu}
            to={`${getRootUrl()}/policy_reports/standards/associated`}
          >
            {t("nav.primary.reports.verge.associated", {
              documentLabelPlural: titleCase(documentLabelPlural)
            })}
          </MenuLink>
        )}
    </SubMenu>
  );
};
const Reports = withViewer(withLabelContext(_Reports));

const _Settings = ({
  expandedMenu,
  closeMenu,
  tenant,
  viewer,
  labels: { documentLabel },
  ...otherProps
}) => {
  const { t } = useTranslation();
  const enableAllBlocs = tenant.settings.enableAllBlocs;
  const showUserGroups =
    !enableAllBlocs && viewer.userAuth.canManageCertifications;
  const showAcknowledgments =
    !enableAllBlocs &&
    viewer.userAuth.canManageCertifications &&
    tenant.acknowledgmentsEnabled;
  const showReviewSets =
    !enableAllBlocs && viewer.userAuth.canViewReviewSetList;
  const showStandardsAndRegs = tenant.settings.standardsAndRegulationsEnabled;
  const showAdminConsole = viewer.userAuth.isSiteAdmin;

  if (
    !showUserGroups &&
    !showAcknowledgments &&
    !showReviewSets &&
    !showStandardsAndRegs &&
    !showAdminConsole &&
    enableAllBlocs
  ) {
    return null;
  }
  return (
    <SubMenu
      name={t("nav.primary.manage.menuName")}
      expanded={expandedMenu === t("nav.primary.manage.menuName")}
      closeMenu={closeMenu}
      {...otherProps}
    >
      {!enableAllBlocs && (
        <Fragment>
          {showUserGroups && (
            <MenuLink href={`${getRootUrl()}/user_group/`} onClick={closeMenu}>
              {t("nav.primary.manage.usersGroup")}
              <StyledFontAwesomeIcon
                icon={["far", "external-link"]}
                size="xs"
              />
            </MenuLink>
          )}
          {showAcknowledgments && (
            <Fragment>
              <MenuLink
                href={`${getRootUrl()}/acknowledgment/document_list/`}
                onClick={closeMenu}
              >
                {t("acknowledgments.assign.header")}
                <StyledFontAwesomeIcon
                  icon={["far", "external-link"]}
                  size="xs"
                />
              </MenuLink>
              <MenuLink
                href={`${getRootUrl()}/recurring_acknowledgment/documents/`}
                onClick={closeMenu}
              >
                {t("nav.primary.manage.recurringAcknowledgments")}
                <StyledFontAwesomeIcon
                  icon={["far", "external-link"]}
                  size="xs"
                />
              </MenuLink>
            </Fragment>
          )}
          <MenuLink href={`${getRootUrl()}/committee/`} onClick={closeMenu}>
            {t("nav.primary.manage.committees")}
            <StyledFontAwesomeIcon icon={["far", "external-link"]} size="xs" />
          </MenuLink>
          {showReviewSets && (
            <MenuLink
              href={`${getRootUrl()}/policy_review/set/`}
              onClick={closeMenu}
            >
              {t("nav.primary.manage.reviewSets")}
              <StyledFontAwesomeIcon
                icon={["far", "external-link"]}
                size="xs"
              />
            </MenuLink>
          )}
        </Fragment>
      )}
      {showStandardsAndRegs && (
        <MenuLink
          href={`${getRootUrl()}/standards_and_regulations/library`}
          onClick={closeMenu}
        >
          {t("nav.primary.manage.standardsAndRegs")}
          <StyledFontAwesomeIcon icon={["far", "external-link"]} size="xs" />
        </MenuLink>
      )}
      {showAdminConsole && (
        <Fragment>
          <SubMenuDivider />
          <MenuLink
            to={`${getRootUrl()}/admin/`}
            onClick={closeMenu}
            className="admin_menu"
          >
            <StyledFontAwesomeIcon icon={["fas", "user-shield"]} size="xs" />
            {t("nav.primary.manage.adminConsole")}
          </MenuLink>
        </Fragment>
      )}
    </SubMenu>
  );
};

const Settings = withLabelContext(_Settings);

const getHelpLink = (helpLinks, routeName) => {
  if (routeName === undefined) {
    return undefined;
  }
  const helpLink = helpLinks.find(obj => obj.name === routeName);
  if (helpLink === undefined) {
    return undefined;
  }
  return helpLink.helpLink;
};

const _Help = ({ expandedMenu, closeMenu, ...otherProps }) => {
  const { t } = useTranslation();
  const helpLink = getHelpLink(otherProps.helpLinks, otherProps.routeName);
  return (
    <SubMenu
      name={t("nav.primary.help.menuName")}
      expanded={expandedMenu === t("nav.primary.help.menuName")}
      rtl={true}
      closeMenu={closeMenu}
      {...otherProps}
    >
      {helpLink && (
        <MenuLink href={helpLink} target="_blank" onClick={closeMenu}>
          {t("nav.primary.help.withThisPage")}
        </MenuLink>
      )}
      <MenuLink
        href="https://rldatix-public.zendesk.com/hc/en-us"
        target="_blank"
        onClick={closeMenu}
      >
        {t("nav.primary.help.supportHUB")}
      </MenuLink>
      {!otherProps.viewer.isGuest && (
        <MenuLink
          href="https://rldatix-public.zendesk.com/hc/en-us/articles/10458412538268-PolicyStat-Education-Webinar-Schedule"
          target="_blank"
          onClick={closeMenu}
        >
          {t("nav.primary.help.education")}
        </MenuLink>
      )}
      <MenuLink
        href={`${getRootUrl()}/whats_new/update_process/`}
        target="_blank"
        onClick={closeMenu}
      >
        {t("nav.primary.help.maintenance")}
      </MenuLink>
    </SubMenu>
  );
};

const Help = withViewer(_Help);

const GuestLogin = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const openForm = () => setIsOpen(true);
  const closeForm = () => setIsOpen(false);

  return (
    <Fragment>
      <MenuLink onClick={openForm}>{t("buttons.login")}</MenuLink>
      <LoginModal isOpen={isOpen} onClose={closeForm} />
    </Fragment>
  );
};

const MyAccount = ({
  expandedMenu,
  closeMenu,
  unseenNotificationsCount = 0,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { usersCanProxy, viewer } = otherProps;
  const userDisplay = viewer.userDisplay;
  return (
    <SubMenu
      name={userDisplay}
      expanded={expandedMenu === userDisplay}
      rtl={true}
      closeMenu={closeMenu}
      renderName={
        <MenuButton color="nav.100" px={2} active={otherProps.active}>
          {viewer.canReverseProxyLogin && (
            <Text color="nav.55" pr={1}>
              {t("nav.primary.myAccount.proxiedLabel")}
            </Text>
          )}
          {userDisplay}
        </MenuButton>
      }
      {...otherProps}
    >
      <MenuLink href={`${getRootUrl()}/account/mine/`} onClick={closeMenu}>
        {t("nav.primary.myAccount.menuName")}
        <StyledFontAwesomeIcon icon={["far", "external-link"]} size="xs" />
      </MenuLink>
      {!viewer.isGuest && (
        <Fragment>
          <MenuLink
            onClick={closeMenu}
            to={{
              pathname: `${getRootUrl()}/notification/`
            }}
          >
            {t("nav.primary.myAccount.notifications", {
              unseenCount: unseenNotificationsCount
            })}
          </MenuLink>
          <SubMenuDivider />
        </Fragment>
      )}
      <LogoutMenuLink closeMenu={closeMenu} />
      {usersCanProxy && usersCanProxy.length > 0 && (
        <Fragment>
          <SubMenuDivider bg="nav.95" />
          <ProxyLoginMenuLink usersCanProxy={usersCanProxy} />
        </Fragment>
      )}
      {viewer.canReverseProxyLogin && (
        <Fragment>
          <SubMenuDivider bg="nav.95" />
          <ProxyLoginReverseMenuLink />
        </Fragment>
      )}
    </SubMenu>
  );
};

// Exporting for testing purpose
export const ChangeLocation = ({
  expandedMenu,
  tenant,
  accessibleTenants,
  ...otherProps
}) => {
  const { t } = useTranslation();
  return (
    <Media query={{ minWidth: theme.breakpoints[1] }}>
      {isDesktop =>
        !isDesktop && (
          <div>
            <SubMenu
              id="change-location-menu"
              name={t("siteSelector.changeLocation")}
              expanded={expandedMenu === t("siteSelector.changeLocation")}
              rtl={false}
              {...otherProps}
            >
              <AccessibleTenantsDropdownItems
                tenant={tenant}
                accessibleTenants={accessibleTenants}
              />
            </SubMenu>
            <MenuText>{tenant.name}</MenuText>
          </div>
        )
      }
    </Media>
  );
};

// need to add this for the closeMenu prop
// because it gets set on direct children of the Menu
// component. And since we need to use React.Fragment
// around the function the closeMenu prop would get added
// to the fragment instead
const MyAccountCloseMenuForwarder = props => (
  <Fragment>
    <MyAccount {...props} />
  </Fragment>
);

// closeMenu is not fowarded when this element
// is rendered in the small screen nav
const ChangeLocationCloseMenuForwarder = props => (
  <Fragment>
    <MenuDivider />
    <ChangeLocation {...props} />
    <MenuDivider />
  </Fragment>
);

class _PrimaryNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedMenu: null
    };
  }

  toggleSubMenu = name => {
    if (this.state.expandedMenu === name) {
      this.toggleSubMenuOff();
    } else {
      this.setState({ expandedMenu: name });
    }
  };

  toggleSubMenuOff = () => {
    this.setState({ expandedMenu: null });
  };

  isMultiTenant = () => {
    return (
      this.props.accessibleTenants && this.props.accessibleTenants.length > 1
    );
  };

  render() {
    const {
      selected,
      routeName,
      accessibleTenants,
      documentTemplates,
      unseenCount,
      viewer,
      tenant,
      t
    } = this.props;
    const { expandedMenu } = this.state;
    const policiesLinkProps = {
      selected,
      expandedMenu,
      toggle: this.toggleSubMenu,
      toggleOff: this.toggleSubMenuOff
    };
    //Accordingly to design, Policies menu item should be active on Search page
    // And on View Policy page
    if (routeName === "search" || routeName === "document_view") {
      // If we would pass `active` prop directly to Policies component - we will override internal router `active` prop
      policiesLinkProps.active = true;
    }
    const dropdownAccessibleTenantItems = accessibleTenants.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });

    const { autoLoginGuest } = tenant.settings;
    const showHelpMenu = !viewer.isGuest || (viewer.isGuest && !autoLoginGuest);

    return (
      <nav aria-label={t("nav.navBar.mainNavigation.ariaLabel")}>
        <HamburgerHeader
          closeSubMenu={this.toggleSubMenuOff}
          submenuExpanded={this.state.expandedMenu}
        >
          <Toolbar>
            <Menu>
              <Home isHomePage={routeName === "home"} />
              <Policies
                id="policies_menu"
                documentTemplates={documentTemplates}
                enableEditWithWord={
                  this.props.tenant.settings.enableEditWithWord
                }
                tenant={this.props.tenant}
                {...policiesLinkProps}
              />
              <Reports
                id="reports_menu"
                selected={selected}
                expandedMenu={this.state.expandedMenu}
                toggle={this.toggleSubMenu}
                toggleOff={this.toggleSubMenuOff}
                scheduledEffectiveDateEnabled={
                  this.props.tenant.hasScheduledEffectiveDateEnabled
                }
                tenant={this.props.tenant}
              />
              {!viewer.isGuest && (
                <Settings
                  id="settings_menu"
                  selected={selected}
                  expandedMenu={this.state.expandedMenu}
                  toggle={this.toggleSubMenu}
                  toggleOff={this.toggleSubMenuOff}
                  viewer={viewer}
                  tenant={this.props.tenant}
                />
              )}
            </Menu>
            <Menu
              rtl={true}
              aria-label={t("nav.navBar.settingsMenu.ariaLabel")}
            >
              <VersionSwitcher />
              {canViewInDevelopmentWork(viewer) && <LanguageSelector />}
              {showHelpMenu && (
                <Help
                  expandedMenu={this.state.expandedMenu}
                  toggle={this.toggleSubMenu}
                  toggleOff={this.toggleSubMenuOff}
                  helpLinks={this.props.helpLinks}
                  routeName={routeName}
                />
              )}
              {this.isMultiTenant() ? (
                <ChangeLocationCloseMenuForwarder
                  tenant={this.props.tenant}
                  accessibleTenants={dropdownAccessibleTenantItems}
                  expandedMenu={this.state.expandedMenu}
                  toggle={this.toggleSubMenu}
                  toggleOff={this.toggleSubMenuOff}
                />
              ) : (
                <MenuDivider />
              )}
              {viewer.isGuest ? (
                <GuestLogin />
              ) : (
                <MyAccountCloseMenuForwarder
                  expandedMenu={this.state.expandedMenu}
                  toggle={this.toggleSubMenu}
                  toggleOff={this.toggleSubMenuOff}
                  viewer={viewer}
                  usersCanProxy={this.props.usersCanProxy}
                  unseenNotificationsCount={unseenCount}
                />
              )}
            </Menu>
          </Toolbar>
        </HamburgerHeader>
      </nav>
    );
  }
}

const PrimaryNav = withTranslation()(withViewer(_PrimaryNav));
PrimaryNav.displayName = "PrimaryNav";

export default PrimaryNav;

PrimaryNav.propTypes = {
  tenant: PropTypes.object.isRequired,
  accessibleTenants: PropTypes.arrayOf(PropTypes.object).isRequired,
  documentTemplates: PropTypes.arrayOf(PropTypes.object),
  helpLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  usersCanProxy: PropTypes.arrayOf(PropTypes.object).isRequired,
  unseenCount: PropTypes.number.isRequired
};

export const PrimaryNavFragmentContainer = createFragmentContainer(
  PrimaryNav,
  graphql`
    fragment PrimaryNav_tenant on TenantType {
      id
      subdomain
      name
      settings {
        enableEditWithWord
        standardsAndRegulationsEnabled
        enableAllBlocs
        autoLoginGuest
        vergeIntegrationEnabled
        documentsWithStandardsReportEnabled
      }
      acknowledgmentsEnabled
      hasScheduledEffectiveDateEnabled
    }

    fragment PrimaryNav_accessibleTenants on TenantType @relay(plural: true) {
      id
      url
      name
    }

    fragment PrimaryNav_documentTemplates on DocumentTemplateType
    @relay(plural: true) {
      pk
      name
    }
  `
);
