import PolicyEditor from "pstat-anywhere/components/document_control/PolicyEditor";
import { EDIT_TYPE } from "pstat-anywhere/components/document_control/PolicyEditor/utils";
import {
  POLICY_ACTIONS,
  TriggerEventOnMount,
  triggerPolicyEvent
} from "pstat-anywhere/utils/googleTagManager";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";

const EditPolicyPage = ({ documentEdit, route }) => {
  if (documentEdit && documentEdit.document) {
    return (
      <TriggerEventOnMount
        triggerEvent={() => {
          const document = documentEdit.document;
          const editForm = documentEdit.editForm;
          triggerPolicyEvent(
            POLICY_ACTIONS.EDIT,
            document.pk,
            document.name,
            editForm.category.pk,
            editForm.category.name
          );
        }}
      >
        <PolicyEditor
          error={false}
          document={documentEdit.document}
          documentSave={documentEdit.documentSave}
          editForm={documentEdit.editForm}
          tagForm={documentEdit.tagForm}
          saveForm={documentEdit.saveForm}
          scheduledEffectiveDateForm={documentEdit.scheduledEffectiveDateForm}
          systemDocumentTemplateForm={documentEdit.systemDocumentTemplateForm}
          policyActions={documentEdit.policyActions}
          route={route}
          alreadyChanged={documentEdit.alreadyChanged}
          rtlSupport={documentEdit.rtlSupport}
          newCkeditor={documentEdit.newCkeditor}
          ckeditor5={documentEdit.ckeditor5}
          type={EDIT_TYPE}
          enabledAutoSaveTimeout={true}
          canLocalize={documentEdit.canLocalize}
        />
      </TriggerEventOnMount>
    );
  } else {
    return <LoadingSpinner size={100} />;
  }
};

export default EditPolicyPage;
