import { toast } from "react-toastify";

import Message, { MESSAGE_TYPES } from "pstat-design-system/message";

let persistentToasts = [];

type ToastOptions = {
  onClose?: Function
};

function createMessage(
  messageType: $Values<typeof MESSAGE_TYPES>,
  messageContent: string | Function,
  toastOptions: ToastOptions = {},
  iconType: $Values<typeof MESSAGE_TYPES>
): void {
  let content,
    render = null;
  if (!messageContent || typeof messageContent === "string") {
    content = messageContent;
  } else {
    render = messageContent;
  }
  const newToastId = toast(
    <Message
      type={messageType}
      content={content}
      render={render}
      iconType={iconType}
    />,
    {
      ...toastOptions,
      onClose: () => {
        // If there is any provided callback - lets call it
        toastOptions.onClose && toastOptions.onClose();
      }
    }
  );
  if (toastOptions.autoClose === false) {
    persistentToasts.push(newToastId);
  }
  return newToastId; // For testing
}

// In order to "hide" internal types, which Message component use
// Here is simple helpers, which just would call createMessage with appropriate MESSAGE_TYPE
export function createSuccessMessage(
  messageContent: string | Function,
  toastOptions: ToastOptions = {}
) {
  return createMessage(MESSAGE_TYPES.SUCCESS, messageContent, toastOptions);
}

export function createRejectSuccessMessage(
  messageContent: string | Function,
  toastOptions: ToastOptions = {}
) {
  return createMessage(
    MESSAGE_TYPES.ERROR,
    messageContent,
    toastOptions,
    MESSAGE_TYPES.SUCCESS
  );
}

export function createErrorMessage(
  messageContent: string | Function,
  toastOptions: ToastOptions = {}
) {
  return createMessage(MESSAGE_TYPES.ERROR, messageContent, toastOptions);
}

export function createWarningMessage(
  messageContent: string | Function,
  toastOptions: ToastOptions = {}
) {
  return createMessage(MESSAGE_TYPES.WARNING, messageContent, toastOptions);
}

export function createInfoMessage(
  messageContent: string | Function,
  toastOptions: ToastOptions = {}
) {
  return createMessage(MESSAGE_TYPES.INFO, messageContent, toastOptions);
}

export function clearToasts() {
  for (let i = 0; i < persistentToasts.length; i++) {
    toast.dismiss(persistentToasts[i]);
  }
  persistentToasts = [];
}
