import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "styled-system";

import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";
import UserAndUserGroupAutocompleteInput from "pstat-design-system/inputs/UserAndUserGroupAutocompleteInput";
import { Button } from "pstat-design-system/shared/Button";
import { fontFamily } from "pstat-design-system/utils";

const AddUserLabel = styled.p`
  color: ${themeGet("colors.nav.0")};
  font-size: ${themeGet("fontSizes.1")};
  font-weight: medium;
  padding-bottom: ${themeGet("space.1")};

  ${fontFamily};
`;

const AssignAllVersionsLabel = styled.div`
  color: ${themeGet("colors.nav.0")};
  font-size: ${themeGet("fontSizes.2")};

  ${fontFamily};
`;

class AssignAcknowledgment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignAllVersions: false,
      selectedSuggestions: []
    };
  }

  handleAssignedAllVersions = () => {
    this.setState({ assignAllVersions: !this.state.assignAllVersions });
  };

  handleSuggestionSelected = selectedSuggestions => {
    this.setState({
      selectedSuggestions
    });
  };

  handleAssign = () => {
    const { onAssign } = this.props;
    const { selectedSuggestions, assignAllVersions } = this.state;

    onAssign({ selectedSuggestions, assignAllVersions });
  };

  render() {
    const { t } = this.props;
    const { selectedSuggestions, assignAllVersions } = this.state;
    const hasSelectedSuggestions = selectedSuggestions.length > 0;
    let selectedCheckboxValues = [];
    if (assignAllVersions) {
      selectedCheckboxValues = ["assign-all-version-checkbox"];
    }
    return (
      <Fragment>
        <AddUserLabel>
          {hasSelectedSuggestions
            ? t("acknowledgments.assign.addUserLabel.addUsersOrRemove")
            : t("acknowledgments.assign.addUserLabel.addUsers")}
        </AddUserLabel>
        <UserAndUserGroupAutocompleteInput
          id="assign_ack_user_input"
          onSuggestionSelected={this.handleSuggestionSelected}
          selectedSuggestions={selectedSuggestions}
          selectMultiple={true}
        />
        <CheckboxGroup
          pt={2}
          pb={1}
          px={0}
          name={"assign-all-versions"}
          onChange={this.handleAssignedAllVersions}
          selectedValues={selectedCheckboxValues}
        >
          <Checkbox
            value="assign-all-version-checkbox"
            renderLabel={() => (
              <AssignAllVersionsLabel>
                {t("acknowledgments.assign.assignAllVersionsLabel")}
              </AssignAllVersionsLabel>
            )}
          />
        </CheckboxGroup>
        <Button
          id="assign_ack_button"
          buttonStyle="secondary"
          disabled={!hasSelectedSuggestions}
          onClick={this.handleAssign}
        >
          {t("buttons.assign")}
        </Button>
      </Fragment>
    );
  }
}

export default withTranslation()(AssignAcknowledgment);
