import { Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import system from "system-components";

import ImportFromWordModal from "./ImportFromWordModal";

import Editor from "pstat-anywhere/components/editor/editor";
import theme from "pstat-anywhere/themes/policystat/theme";
import {
  CSRF_TOKEN_NAME,
  getCSRFToken
} from "pstat-anywhere/utils/customFetch";
import {
  POLICY_ACTIONS,
  triggerPolicyEvent
} from "pstat-anywhere/utils/googleTagManager";
import { getUrl } from "pstat-anywhere/utils/urls";
import Panel from "pstat-design-system/Panel";
import { H2, SubmitExportButton, Text } from "pstat-design-system/text";


const ImportText = system({
  is: Text,
  color: "secondary.0",
  ml: 4
}).extend`
  cursor: pointer;
`;

const ImportFromWord = props => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      openModal();
    }
  };
  return (
    <Fragment>
      <ImportText tabIndex="0" onClick={openModal} onKeyDown={handleKeyDown}>
        {t("documentControl.create.importFromWord.import")}
      </ImportText>
      <ImportFromWordModal
        isOpen={open}
        onClose={closeModal}
        html={props.html}
        onHtmlChange={props.onHtmlChange}
        documentSave={props.documentSave}
        setAttachments={props.setAttachments}
        document={props.document}
        isOverridePage={props.isOverridePage}
      />
    </Fragment>
  );
};

const Export = ({ name, html, temporarilyDisableBeforeUnload, document }) => {
  const { t } = useTranslation();
  const exportToWord = () => {
    temporarilyDisableBeforeUnload();
    triggerPolicyEvent(
      POLICY_ACTIONS.DOWNLOAD_TO_WORD,
      document?.pk,
      name,
      document?.category?.pk,
      document?.category?.name
    );
  };

  return (
    <form
      method="POST"
      action={getUrl("/msword/download/")}
      id="editor-export-form"
      onSubmit={exportToWord}
      style={{
        /* 
          The form will not submit if the form is an 
          Styled Components,
          so we need the inline style
        */
        display: "contents"
      }}
    >
      <input type="hidden" name="name" value={name} />
      <input type="hidden" name="html" value={html} />
      <input type="hidden" name={CSRF_TOKEN_NAME} value={getCSRFToken()} />
      <SubmitExportButton
        type="submit"
        tabIndex="0"
        name="export-word-document"
        value={t("documentControl.create.exportFromWord.export")}
      />
    </form>
  );
};

const Header = ({
  name,
  policyName,
  policyHtml,
  onHtmlChange,
  documentSave,
  setAttachments,
  temporarilyDisableBeforeUnload,
  enableEditWithWord,
  document,
  isOverridePage
}) => (
  <Flex justifyContent="space-between">
    <H2 color="nav.0" fontWeight="normal">
      {name}
    </H2>
    {enableEditWithWord && (
      <Media query={{ minWidth: theme.breakpoints[1] }}>
        {isDesktop => (
          <Flex
            alignItems="baseline"
            justifyContent={isDesktop ? "flex-end" : "flex-start"}
          >
            <Export
              name={policyName}
              html={policyHtml}
              temporarilyDisableBeforeUnload={temporarilyDisableBeforeUnload}
              document={document}
            />
            <ImportFromWord
              html={policyHtml}
              onHtmlChange={onHtmlChange}
              documentSave={documentSave}
              setAttachments={setAttachments}
              document={document}
              isOverridePage={isOverridePage}
            />
          </Flex>
        )}
      </Media>
    )}
  </Flex>
);

const EditorSection = ({
  policyForm,
  onChange,
  onDataReady,
  documentSave,
  rtlSupport,
  newCkeditor,
  ckeditor5,
  setAttachments,
  temporarilyDisableBeforeUnload,
  enableEditWithWord,
  setEditor,
  document,
  isOverridePage,
  editorStyles
}) => {
  const { t } = useTranslation();
  return (
    <Panel
      id="editor"
      name={t("documentControl.edit.editorSection")}
      hideHeaderBorder={true}
      renderHeader={Header}
      policyName={policyForm.name}
      policyHtml={policyForm.html}
      onHtmlChange={onChange}
      documentSave={documentSave}
      setAttachments={setAttachments}
      temporarilyDisableBeforeUnload={temporarilyDisableBeforeUnload}
      enableEditWithWord={enableEditWithWord}
      document={document}
      isOverridePage={isOverridePage}
    >
      <Editor
        content={policyForm.html}
        onChange={onChange}
        onDataReady={onDataReady}
        rtlSupport={rtlSupport}
        newCkeditor={newCkeditor}
        ckeditor5={ckeditor5}
        setEditor={setEditor}
        editorStyles={editorStyles}
      />
    </Panel>
  );
};

export default EditorSection;
