/**
 * @flow
 * @relayHash d5939a0c2dbe86951c57e11959186502
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PrimaryNav_accessibleTenants$ref = any;
type PrimaryNav_documentTemplates$ref = any;
type PrimaryNav_tenant$ref = any;
type SecondaryNav_accessibleTenants$ref = any;
type SecondaryNav_tenant$ref = any;
export type PageWithNavQueryVariables = {||};
export type PageWithNavQueryResponse = {|
  +currentTenant: ?{|
    +documentSettings: ?{|
      +documentTemplates: ?$ReadOnlyArray<?{|
        +$fragmentRefs: PrimaryNav_documentTemplates$ref
      |}>
    |},
    +$fragmentRefs: PrimaryNav_tenant$ref & SecondaryNav_tenant$ref,
  |},
  +accessibleTenants: ?$ReadOnlyArray<?{|
    +pk: ?number,
    +name: string,
    +isAllBlocs: ?boolean,
    +url: ?string,
    +$fragmentRefs: PrimaryNav_accessibleTenants$ref & SecondaryNav_accessibleTenants$ref,
  |}>,
  +helpLinks: ?$ReadOnlyArray<?{|
    +name: ?string,
    +helpLink: ?string,
    +showPopup: ?boolean,
  |}>,
  +usersCanProxy: ?$ReadOnlyArray<?{|
    +pk: ?number,
    +userDisplay: ?string,
  |}>,
  +notifications: ?{|
    +unseenCount: ?number
  |},
|};
export type PageWithNavQuery = {|
  variables: PageWithNavQueryVariables,
  response: PageWithNavQueryResponse,
|};
*/


/*
query PageWithNavQuery {
  currentTenant {
    ...PrimaryNav_tenant
    ...SecondaryNav_tenant
    documentSettings {
      documentTemplates {
        ...PrimaryNav_documentTemplates
        id
      }
      id
    }
    id
  }
  accessibleTenants {
    ...PrimaryNav_accessibleTenants
    ...SecondaryNav_accessibleTenants
    pk
    name
    isAllBlocs
    url
    id
  }
  helpLinks {
    name
    helpLink
    showPopup
  }
  usersCanProxy {
    pk
    userDisplay
    id
  }
  notifications(first: 0) {
    unseenCount
  }
}

fragment PrimaryNav_tenant on TenantType {
  id
  subdomain
  name
  settings {
    enableEditWithWord
    standardsAndRegulationsEnabled
    enableAllBlocs
    autoLoginGuest
    vergeIntegrationEnabled
    documentsWithStandardsReportEnabled
    id
  }
  acknowledgmentsEnabled
  hasScheduledEffectiveDateEnabled
}

fragment SecondaryNav_tenant on TenantType {
  id
  subdomain
  name
}

fragment PrimaryNav_documentTemplates on DocumentTemplateType {
  pk
  name
}

fragment PrimaryNav_accessibleTenants on TenantType {
  id
  url
  name
}

fragment SecondaryNav_accessibleTenants on TenantType {
  id
  url
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAllBlocs",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "helpLinks",
  "storageKey": null,
  "args": null,
  "concreteType": "HelpLinkType",
  "plural": true,
  "selections": [
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "helpLink",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "showPopup",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userDisplay",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "notifications",
  "storageKey": "notifications(first:0)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0,
      "type": "Int"
    }
  ],
  "concreteType": "NoticeCountableConnection",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unseenCount",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PageWithNavQuery",
  "id": "1017f663449292ff43c7918f33c0a95b",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PageWithNavQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "PrimaryNav_tenant",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "SecondaryNav_tenant",
            "args": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSettingsType",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "documentTemplates",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentTemplateType",
                "plural": true,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "PrimaryNav_documentTemplates",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "accessibleTenants",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "PrimaryNav_accessibleTenants",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "SecondaryNav_accessibleTenants",
            "args": null
          },
          v0,
          v1,
          v2,
          v3
        ]
      },
      v4,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "usersCanProxy",
        "storageKey": null,
        "args": null,
        "concreteType": "UserType",
        "plural": true,
        "selections": [
          v0,
          v5
        ]
      },
      v6
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PageWithNavQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          v7,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subdomain",
            "args": null,
            "storageKey": null
          },
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantSettingsType",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "enableEditWithWord",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "standardsAndRegulationsEnabled",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "enableAllBlocs",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "autoLoginGuest",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "vergeIntegrationEnabled",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "documentsWithStandardsReportEnabled",
                "args": null,
                "storageKey": null
              },
              v7
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "acknowledgmentsEnabled",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasScheduledEffectiveDateEnabled",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSettingsType",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "documentTemplates",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentTemplateType",
                "plural": true,
                "selections": [
                  v0,
                  v1,
                  v7
                ]
              },
              v7
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "accessibleTenants",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": true,
        "selections": [
          v7,
          v3,
          v1,
          v0,
          v2
        ]
      },
      v4,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "usersCanProxy",
        "storageKey": null,
        "args": null,
        "concreteType": "UserType",
        "plural": true,
        "selections": [
          v0,
          v5,
          v7
        ]
      },
      v6
    ]
  }
};
})();
node.text = "query PageWithNavQuery {\n  currentTenant {\n    ...PrimaryNav_tenant\n    ...SecondaryNav_tenant\n    documentSettings {\n      documentTemplates {\n        ...PrimaryNav_documentTemplates\n        id\n      }\n      id\n    }\n    id\n  }\n  accessibleTenants {\n    ...PrimaryNav_accessibleTenants\n    ...SecondaryNav_accessibleTenants\n    pk\n    name\n    isAllBlocs\n    url\n    id\n  }\n  helpLinks {\n    name\n    helpLink\n    showPopup\n  }\n  usersCanProxy {\n    pk\n    userDisplay\n    id\n  }\n  notifications(first: 0) {\n    unseenCount\n  }\n}\n\nfragment PrimaryNav_tenant on TenantType {\n  id\n  subdomain\n  name\n  settings {\n    enableEditWithWord\n    standardsAndRegulationsEnabled\n    enableAllBlocs\n    autoLoginGuest\n    vergeIntegrationEnabled\n    documentsWithStandardsReportEnabled\n    id\n  }\n  acknowledgmentsEnabled\n  hasScheduledEffectiveDateEnabled\n}\n\nfragment SecondaryNav_tenant on TenantType {\n  id\n  subdomain\n  name\n}\n\nfragment PrimaryNav_documentTemplates on DocumentTemplateType {\n  pk\n  name\n}\n\nfragment PrimaryNav_accessibleTenants on TenantType {\n  id\n  url\n  name\n}\n\nfragment SecondaryNav_accessibleTenants on TenantType {\n  id\n  url\n  name\n}\n";
(node/*: any*/).hash = 'f90645f9ac111caa507f567c5ecc2500';
module.exports = node;
