import { Box, Flex } from "grid-styled";
import { Fragment, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import system from "system-components";

import ReferencesPanel from "./ReferencesPanel";

import AcknowledgmentsPanel from "pstat-anywhere/components/acknowledgments/AcknowledgmentsPanel";
import OptionalApproval from "pstat-anywhere/components/document_control/approve_policy/OptionalApproval";
import { isOptionalApproval } from "pstat-anywhere/components/document_control/approve_policy/utils";
import OneSourceDocumentsPanel from "pstat-anywhere/components/document_control/onesource/OneSourcePanel";
import ShowChangesLoadingModal from "pstat-anywhere/components/document_control/ShowChangesLoadingModal";
import ApplicabilityPanel from "pstat-anywhere/components/document_control/view_policy/applicability/ApplicabilityPanel";
import ApprovalSignatureSection from "pstat-anywhere/components/document_control/view_policy/approval_signature";
import CommentPanel from "pstat-anywhere/components/document_control/view_policy/comment_form";
import DocumentApprovedMessage from "pstat-anywhere/components/document_control/view_policy/DocumentApprovedMessage";
import HistoryCard from "pstat-anywhere/components/document_control/view_policy/history";
import PolicyProperties, {
  NUM_ITEMS_TO_DISPLAY_IN_HEADER
} from "pstat-anywhere/components/document_control/view_policy/policy_properties/PolicyProperties";
import RevisionsPanel from "pstat-anywhere/components/document_control/view_policy/revisions/Revisions";
import SecondaryNav from "pstat-anywhere/components/document_control/view_policy/secondarynav/SecondaryNav";
import useShowDocumentChanges from "pstat-anywhere/components/document_control/view_policy/show_changes/hooks/useShowDocumentChanges";
import StandardsAndRegulationsPanel from "pstat-anywhere/components/document_control/view_policy/standards_and_regulations/StandardsAndRegulationsPanel";
import StatusBar from "pstat-anywhere/components/document_control/view_policy/StatusBar";
import TableOfContents, {
  useTableOfContents
} from "pstat-anywhere/components/document_control/view_policy/table_of_contents";
import { VergeStandardsPanel } from "pstat-anywhere/components/document_control/view_policy/VergeStandards";
import { DocumentTimeline } from "pstat-anywhere/components/document_timeline";
import LippincottSearchPanel from "pstat-anywhere/components/integrations/lippincott_search/LippincottSearchPanel";
import { EpAssociationViewMenu } from "pstat-anywhere/components/integrations/verge/EpAssociationViewMenu";
import { useEpViewMenu } from "pstat-anywhere/components/integrations/verge/hooks";
import { ContentContainer } from "pstat-anywhere/components/pages";
import AttachmentsPanel from "pstat-anywhere/components/view/Attachments";
import PolicyContent from "pstat-anywhere/components/view/content/Content";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import { withViewer } from "pstat-anywhere/context_providers/ViewerContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import {
  isActive,
  isPending,
  isScheduled,
  shouldShowOriginationDate
} from "pstat-anywhere/utils/document";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import {
  createErrorMessage,
  createInfoMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";


const ViewPolicyStyledContainer = system({
  bg: "nav.95"
}).extend`
  & sub,
  & sup {
    font-size: smaller;
  }
  & sup {
    vertical-align: super;
  }
  & sub {
    vertical-align: sub;
  }
`;
ViewPolicyStyledContainer.displayName = "ViewPolicyStyledContainer";

export const ViewContentContainer = ({
  isDesktop,
  tocExpandedDesktop,
  children
}) => (
  <ContentContainer
    id="view-content"
    maxWidth={isDesktop && tocExpandedDesktop ? "calc(100% - 320px)" : "100%"}
  >
    {children}
  </ContentContainer>
);

const OtherVersionToast = ({ onClick, buttonText, text }) => (
  <Flex alignItems="center" flexWrap="wrap">
    <Text color="nav.100">{text}</Text>
    <Button buttonStyle="tertiary" onClick={onClick} mt={1}>
      {buttonText}
    </Button>
  </Flex>
);

const ViewPolicy = props => {
  const {
    documentView,
    viewer,
    tenant,
    router,
    location,
    route,
    hasPolicyActionComment,
    PolicyActionCommentSection,
    isDesktop = true,
    displayConfig = {},
    actionDocumentPk
  } = props;

  const {
    document,
    documentAccessTokens,
    guestAccessLink,
    showChanges,
    canCreateReadReceipt,
    canEditDocument,
    showTimeline,
    lippincottEnabled,
    documentSave
  } = documentView;
  const { pk, isTemplate } = document;

  const policyActions = props.policyActions || documentView.policyActions;
  const [displayChangesDiff, setDisplayChangesDiff] = useState(false);
  const [checkingChangesDiff, setCheckingChangesDiff] = useState(false);

  const [applicabilityExpanded, setApplicabilityExpanded] = useState(true);
  const [acknowlegmentExpanded, setAcknowlegmentExpanded] = useState(false);
  const [showChangesTaskId, setShowChangesTaskId] = useState(
    documentView.showChangesTaskId
  );
  const getInitApprovable = () => documentView?.document.approvable !== "NO";
  // this state never changes, but I think it is like that on purpose to make
  // sure that `initApprovalbe` doesn't change on rerenders even if
  // `document.approvable` does
  const [initApprovable] = useState(getInitApprovable);
  const [activeTOCHeaderId, setActiveTOCHeaderId] = useState(null);

  // the document state for the first action in the selected timeline range
  const [timelineDocument, setTimelineDocument] = useState(null);
  const isInitialTimelineChangesLoad = useRef(true);

  /*
    The next state blocks showing the "already approved page"
    when approval is already being processed
  */
  const [isApprovalInProgress, setIsApprovalInProgress] = useState(false);
  const [includePastApprovals, setIncludePastApprovals] = useState(false);

  const [
    showEpAssociationViewMenu,
    epContent,
    openEpAssociationMenu,
    closeEpAssociationMenu
  ] = useEpViewMenu();

  const diff = useRef(null);

  // track the timeline selection for printing changes
  const beforeActionPk = useRef(null);
  const afterActionPk = useRef(null);
  const handleTimelineSelectionChange = (
    updatedBeforeActionPk,
    updatedAfterActionPk
  ) => {
    beforeActionPk.current = updatedBeforeActionPk;
    afterActionPk.current = updatedAfterActionPk;
  };
  const showChangesTaskSuccessHandler = taskResult => {
    diff.current = taskResult;
    isInitialTimelineChangesLoad.current = false;
  };
  const showChangesTaskResult = useShowDocumentChanges(
    showChangesTaskId,
    showChangesTaskSuccessHandler
  );
  const { loading: taskLoading } = showChangesTaskResult;

  const {
    tocExpandedDesktop,
    tocExpandedMobile,
    toggleTableOfContents,
    closeTableOfContents
  } = useTableOfContents(isDesktop);

  const handleDisplayChangesDiff = () => {
    setDisplayChangesDiff(!displayChangesDiff);
  };

  const showMsg = (response, errors, msgId) => {
    const { t } = props;
    if (errors) {
      errors.forEach(error => {
        createErrorMessage(error);
      });
    } else {
      createSuccessMessage(t(msgId));
    }
  };

  const assignAcknowledgmentMsg = (response, errors) => {
    showMsg(response, errors, "acknowledgments.assign.successMessage");
  };

  const deleteAcknowledgmentMsg = (response, errors) => {
    showMsg(response, errors, "acknowledgments.assign.successMessage");
  };

  const handleTimelineChangeTask = (initialDocument, taskId) => {
    setTimelineDocument(initialDocument);
    setShowChangesTaskId(taskId);
  };

  useEffect(() => {
    const { t } = props;
    const document = props.documentView.document;
    const documentLabel = props.labels.documentLabel.toLowerCase();
    if (document.draftVersionLink) {
      createInfoMessage(
        () => (
          <OtherVersionToast
            onClick={() => props.router.push(document.draftVersionLink)}
            buttonText={t("documentControl.view.toasts.draftButton")}
            text={t("documentControl.view.toasts.draftText", { documentLabel })}
          />
        ),
        { autoClose: false, toastId: "draft-version-toast" }
      );
    } else if (document.pendingVersionLink) {
      createInfoMessage(
        () => (
          <OtherVersionToast
            onClick={() => props.router.push(document.pendingVersionLink)}
            buttonText={t("documentControl.view.toasts.pendingButton")}
            text={t("documentControl.view.toasts.pendingText")}
          />
        ),
        { autoClose: false, toastId: "pending-version-toast" }
      );
    } else if (document.scheduledVersionLink) {
      createInfoMessage(
        () => (
          <OtherVersionToast
            onClick={() => props.router.push(document.scheduledVersionLink)}
            buttonText={t("documentControl.view.toasts.scheduledButton")}
            text={t("documentControl.view.toasts.scheduledText")}
          />
        ),
        { autoClose: false, toastId: "scheduled-version-toast" }
      );
    }
  }, [props]);

  const isViewRoute = route?.data?.routeName === "document_view";
  const showOriginationDate = shouldShowOriginationDate(tenant);
  const pending = isPending(document.status);
  const isApprovalRoute = route?.data?.routeName === "document_approve";
  const isApprovalPage = isApprovalRoute && document.approvable !== "NO";
  const showDocumentAlreadyApprovedMessage =
    isApprovalRoute && !initApprovable && !isApprovalInProgress && !pending;

  const documentToDisplay = timelineDocument || document;

  const {
    showPrintAndShare = true,
    showLippincottPanel = true,
    showApplicabilityPanel = true,
    showHistoryPanel = true,
    showCommentsPanel = true,
    showApprovalFlow = false,
    showCloneAction = true
  } = displayConfig;

  let {
    showAcknowledgementsPanel = true,
    showApprovalSignaturePanel = true
  } = displayConfig;

  const showLippincottSearch =
    showLippincottPanel && document.isLippincottDocument && lippincottEnabled;

  // Ack Panel depends on a status of a document:
  // show a panel in a viewing page for active/scheduled document.
  if (showAcknowledgementsPanel) {
    if (
      !isViewRoute ||
      (!isActive(document.status) && !isScheduled(document.status))
    ) {
      showAcknowledgementsPanel = false;
    }
  }

  if (documentToDisplay.approvalSignatures.length === 0) {
    showApprovalSignaturePanel = false;
  }

  const lippincottSearchHandle = searchQuery => {
    const to = {
      pathname: `${getRootUrl()}/integrations/lippincott/search`,
      query: { q: searchQuery }
    };
    router.push(to);
  };

  const [pageHasReachedTheEnd, setPageHasReachedTheEnd] = useState(false);

  const checkIfReachedEnd = () => {
    const finalContentElement = window.document.getElementById(
      "end-of-content"
    );
    const hasReachedEnd =
      finalContentElement &&
      finalContentElement.getBoundingClientRect().bottom <= window.innerHeight;

    setPageHasReachedTheEnd(prevValue =>
      prevValue ? prevValue : hasReachedEnd
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfReachedEnd);

    return () => {
      window.removeEventListener("scroll", checkIfReachedEnd);
    };
  });

  useEffect(() => {
    if (location.hash) {
      router.replace(location);
    }
    // only want checking hash during the first page loading,
    // cause before loading a full page we can't scroll to a necessary heading.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasMoreStandardsAndRegulations = () => {
    return (
      documentToDisplay.regulations &&
      documentToDisplay.regulations.length > NUM_ITEMS_TO_DISPLAY_IN_HEADER
    );
  };

  const togglePastApproval = () =>
    setIncludePastApprovals(!includePastApprovals);

  const countVergeStandards = tenant.vergeIntegrationEnabled
    ? document.epAssociationDocuments?.length
    : undefined;

  const countOneSource = documentToDisplay.onesourceDocuments?.length;

  const { documents: documentsOfTemplate, systemDocumentTemplate } =
    documentToDisplay.documentsOfTemplate || {};

  const TOCParams = {
    countAttachments: documentToDisplay.attachments.length,
    countStandards: countVergeStandards,
    countOneSource: countOneSource,
    html: documentToDisplay.html,
    expanded: isDesktop ? tocExpandedDesktop : tocExpandedMobile,
    activeItemId: activeTOCHeaderId,
    onToggle: toggleTableOfContents,
    onClose: closeTableOfContents
  };

  return (
    <Fragment>
      {showDocumentAlreadyApprovedMessage ? (
        <DocumentApprovedMessage policyUrl={`${getRootUrl()}/policy/${pk}`} />
      ) : (
        <SecondaryNav
          isApprovalPage={isApprovalPage}
          document={document}
          documentSave={documentSave}
          showChangesToggle={showChanges && showChangesTaskId !== null} // want this to be true if empty string
          showChangesTaskResult={showChangesTaskResult}
          checkingChangesDiff={checkingChangesDiff}
          displayChangesDiff={displayChangesDiff}
          handleDisplayChangesDiff={handleDisplayChangesDiff}
          tocExpanded={tocExpandedDesktop}
          policyActions={policyActions}
          route={route}
          pageHasReachedTheEnd={pageHasReachedTheEnd}
          showCloneAction={showCloneAction}
          setShouldBlockAlreadyApprovedPage={setIsApprovalInProgress}
          isDesktop={isDesktop}
        >
          <TableOfContents {...TOCParams} />
          <ViewContentContainer
            isDesktop={isDesktop}
            tocExpandedDesktop={tocExpandedDesktop}
          >
            {hasPolicyActionComment ? (
              <Box width={1} px={[0, 0, 3]} mt={6}>
                <PolicyActionCommentSection
                  documentName={document.name}
                  documentPk={actionDocumentPk || pk}
                  location={location}
                  router={router}
                />
              </Box>
            ) : null}
            <StatusBar
              document={document}
              timelineDocument={timelineDocument}
              documentAccessTokens={documentAccessTokens}
              guestAccessLink={guestAccessLink}
              viewer={viewer}
              applicabilityExpanded={applicabilityExpanded}
              isApprovalPage={isApprovalPage}
              policyActions={policyActions}
              canEditDocument={canEditDocument}
              showPrintAndShare={showPrintAndShare}
              showWorkflowName={showApprovalFlow}
              displayChangesDiff={displayChangesDiff}
              includePastApprovals={includePastApprovals}
              location={location}
              beforeActionPk={beforeActionPk.current}
              afterActionPk={afterActionPk.current}
            />
            {isDesktop && (
              <PolicyProperties
                approvalDate={documentToDisplay.approvalDate}
                effectiveDate={documentToDisplay.effectiveDate}
                revisionDate={documentToDisplay.revisionDate}
                expirationDate={documentToDisplay.expirationDate}
                originationDate={
                  showOriginationDate
                    ? documentToDisplay.originationDate
                    : documentToDisplay.legacyEffectiveDate
                }
                author={documentToDisplay.author}
                category={documentToDisplay.category}
                bloc={documentToDisplay.bloc}
                tags={documentToDisplay.tags}
                headerImageUrl={documentToDisplay.headerImageUrl}
                regulations={documentToDisplay.regulations}
                onesourceDocuments={documentToDisplay.onesourceDocuments}
                documentsToTemplate={documentsOfTemplate}
                systemDocumentTemplate={systemDocumentTemplate}
              />
            )}
            <PolicyContent
              name={documentToDisplay.name}
              html={displayChangesDiff ? diff.current : documentToDisplay.html}
              tableOfContentsCallback={setActiveTOCHeaderId}
              isRestricted={documentToDisplay.restricted}
            />
            <RevisionsPanel revisionDates={document.revisionDates} />
            <AttachmentsPanel attachments={documentToDisplay.attachments} />
            {documentToDisplay.onesourceDocuments &&
              documentToDisplay.onesourceDocuments.length > 0 && (
                <Box width={1} px={[0, 0, 3]}>
                  <OneSourceDocumentsPanel
                    documents={documentToDisplay.onesourceDocuments}
                  />
                </Box>
              )}
            {tenant.vergeIntegrationEnabled && (
              <Box width={1} px={[0, 0, 3]}>
                <VergeStandardsPanel
                  openEpContentPanel={openEpAssociationMenu}
                  associations={document.epAssociationDocuments}
                />
              </Box>
            )}
            {showLippincottSearch && (
              <Box width={1} px={[0, 0, 3]}>
                <LippincottSearchPanel onSearch={lippincottSearchHandle} />
              </Box>
            )}
            {showApprovalSignaturePanel && (
              <Box width={1} px={[0, 0, 3]}>
                <ApprovalSignatureSection
                  document={documentToDisplay}
                  isOpenPastApproval={includePastApprovals}
                  togglePastApproval={togglePastApproval}
                  showStepDescription={tenant.documentSettings?.showCommittee}
                />
              </Box>
            )}
            {showApplicabilityPanel && tenant.showApplicability && isDesktop && (
              <Box width={1} px={[0, 0, 3]} id="applicability-panel">
                <ApplicabilityPanel
                  document={documentToDisplay}
                  expanded={applicabilityExpanded}
                  onExpandedChanged={setApplicabilityExpanded}
                />
              </Box>
            )}
            {tenant.documentSettings?.referencesShow &&
              documentToDisplay.tags &&
              documentToDisplay.tags.length >
                NUM_ITEMS_TO_DISPLAY_IN_HEADER && (
                <Box width={1} px={[0, 0, 3]}>
                  <ReferencesPanel tags={documentToDisplay.tags} />
                </Box>
              )}
            {isDesktop && hasMoreStandardsAndRegulations() && (
              <Box width={1} px={[0, 0, 3]}>
                <StandardsAndRegulationsPanel
                  regulations={documentToDisplay.regulations}
                />
              </Box>
            )}
            {!viewer.isGuest && (
              <Fragment>
                {showAcknowledgementsPanel &&
                  canCreateReadReceipt &&
                  !isTemplate && (
                    <Box width={1} px={[0, 0, 3]}>
                      <AcknowledgmentsPanel
                        onAssignMsg={assignAcknowledgmentMsg}
                        onDeleteMsg={deleteAcknowledgmentMsg}
                        documentId={documentView?.document?.pk}
                        linePk={documentView?.document?.linePk}
                        expanded={acknowlegmentExpanded}
                        onExpandedChanged={setAcknowlegmentExpanded}
                      />
                    </Box>
                  )}
                {isOptionalApproval(document) && (
                  <Box width={1} px={[0, 0, 3]}>
                    <OptionalApproval document={document} />
                  </Box>
                )}
                {showHistoryPanel && (
                  <Box width={1} px={[0, 0, 3]}>
                    <HistoryCard
                      document={document}
                      actions={document.actions}
                      documentPk={pk}
                      viewer={viewer}
                    />
                  </Box>
                )}
                {showCommentsPanel && (
                  <Box width={1} px={[0, 0, 3]}>
                    <CommentPanel
                      document={document}
                      isEditPage={false}
                      documentName={document.name}
                    />
                  </Box>
                )}
              </Fragment>
            )}
            {showTimeline && (
              <DocumentTimeline
                documentPk={document.pk}
                onTimelineChangesTaskChange={handleTimelineChangeTask}
                onTimelineSelectionChange={handleTimelineSelectionChange}
                onCheckingDiff={setCheckingChangesDiff}
              />
            )}
          </ViewContentContainer>
          <ShowChangesLoadingModal
            isOpen={taskLoading && !isInitialTimelineChangesLoad.current}
          />
          {showEpAssociationViewMenu && (
            <EpAssociationViewMenu
              onClose={closeEpAssociationMenu}
              content={epContent}
            />
          )}
        </SecondaryNav>
      )}
    </Fragment>
  );
};

const ViewPolicyContainer = (props, ...rest) => {
  return (
    <ViewPolicyStyledContainer>
      <Media query={{ minWidth: theme.breakpoints[1] }}>
        {isDesktop => (
          /*
            When the key prop changes,
            React will create a new component instance rather than update the current one.
            Keys are usually used for dynamic lists but are also useful here.
          */
          <ViewPolicy
            {...props}
            {...rest}
            isDesktop={isDesktop}
            key={props?.documentView?.document?.pk}
          />
        )}
      </Media>
    </ViewPolicyStyledContainer>
  );
};

export default withTranslation()(
  withLabelContext(withViewer(withTenantContext(ViewPolicyContainer)))
);
