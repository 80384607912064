import titleCase from "pstat-anywhere/utils/titleCase";
import {
  adoptedDocumentsCounters,
  deleteButton,
  editLink,
  formatAcknowledgedDateForDocument,
  formatAcknowledgedDateForLine,
  formatApplicabilityWithHomeIcon,
  formatApplicabilityWithHomeIconOrListApplicability,
  formatApprovers,
  formatAuthor,
  formatBlocEditIcon,
  formatBlocSites,
  formatDate,
  formatDateShort,
  formatDateShortNoDay,
  formatDateTimeDistance,
  formatDocumentNameWithAttachmentIcon,
  formatDocumentNameWithLockIcon,
  formatDraftSaver,
  formatDraftType,
  formatEpReportAssociatedDateField,
  formatEpReportLastEpUpdateDateField,
  formatEpReportNameField,
  formatImportFile,
  formatPreview,
  formatStatus,
  formatTags,
  formatTemplateAdoptionAuthor,
  formatValueOrEmpty,
  formatViewDuplicates,
  formatWaitTimeForDocument,
  formatWaitTimeForLine,
  formatYesNo,
  viewAdoptedTemplateActions
} from "pstat-design-system/tables/utils/formatters";
import {
  addSelectAllCheckbox,
  frozen,
  onesourceDocumentsActions,
  onesourceResultsActions,
  validateDueDate,
  wrapAcceptPolicyCheckbox,
  wrapToAcceptImportLink,
  wrapToActionCheckbox,
  wrapToApproveOrViewDocumentLink,
  wrapToBlocLink,
  wrapToCategorySearchResultsLink,
  wrapToDocumentApproveLink,
  wrapToDocumentLineLink,
  wrapToDocumentLink,
  wrapToDocumentLinkIfDocument,
  wrapToDocumentOrLineLink,
  wrapToDocumentSaveLink,
  wrapToDocumentShowChangesLink,
  wrapToEditLinkRevision,
  wrapToLatestDocumentLink,
  wrapToLatestDocumentLinkFromSearchPage,
  wrapToLink,
  wrapToLippincottDocumentLink,
  wrapToLippincottSearchResult,
  wrapToSystemTemplateLink,
  wrapToSystemTemplateReviewLink,
  wrapToViewDuplicatesLink
} from "pstat-design-system/tables/utils/transforms";
import { Text } from "pstat-design-system/text";

export const getColumnSortIndexes = columnsArray => {
  return columnsArray.map((column, index) => {
    if (!column.sort) {
      return column;
    }

    const parsedColumn = {};

    if (column.default) {
      Object.assign(parsedColumn, { default: column.default });
    }

    const parsedSortObjects = Object.keys(column.sort).map(key => ({
      [key]: {
        [index]: {
          direction: column.sort[key],
          position: index
        }
      }
    }));
    Object.assign(parsedColumn, ...parsedSortObjects);

    if (column.sort.default) {
      parsedColumn.default = column.sort.default;
    }

    return { ...column, sort: parsedColumn };
  });
};

export const getColumnPresets = (
  sortable,
  showLegacyEffectiveDate,
  t,
  otherArgs
) => {
  let effectiveDateProperty = "effectiveDate";
  let effectiveDateGraphqlLabel = "effectiveDateLabel";

  const { viewOnlyPks, singleTenantCustomer } = otherArgs;

  const removeApplicabilityColumnIfNeeded = columnPreset => {
    if (singleTenantCustomer) {
      return columnPreset.filter(
        column => column.property.indexOf("bloc.name") < 0
      );
    }

    return columnPreset;
  };

  if (showLegacyEffectiveDate) {
    effectiveDateProperty = "legacyEffectiveDate";
    effectiveDateGraphqlLabel = "originationDateLabel";
  }

  const action = t("tables.labels.action");
  const actions = t("tables.labels.actions");
  const applicability = t("tables.labels.applicability");
  const approved = t("tables.labels.approved");
  const category = t("tables.labels.category");
  const count = t("tables.labels.count");
  const created = t("tables.labels.created");
  const dateAcknowledged = t("tables.labels.dateAcknowledged");
  const dateAssigned = t("tables.labels.dateAssigned");
  const dateRead = t("tables.labels.dateRead");
  const documentStatus = t("tables.labels.documentStatusDefault");
  const draftCreated = t("documentDraft.table.created");
  const draftModified = t("documentDraft.table.modified");
  const draftSaver = t("documentDraft.table.creator");
  const draftType = t("documentDraft.table.draftType");
  const effective = t("tables.labels.effective");
  const expiration = t("tables.labels.expiration");
  const filename = t("tables.labels.filename");
  const ipRangeStart = t("tables.labels.ipRangeStart");
  const ipRangeEnd = t("tables.labels.ipRangeEnd");
  const name = t("tables.labels.name");
  const numberDetected = t("tables.labels.numDetected");
  const neededApprovers = t("tables.labels.neededApprovers");
  const preview = t("tables.labels.preview");
  const revised = t("tables.labels.revised");
  const sites = t("tables.labels.sites");
  const status = t("tables.labels.status");
  const stepDescription = t("tables.labels.stepDescription");
  const siteName = t("tables.labels.siteName");
  const subdomain = t("tables.labels.subdomain");
  const title = t("tables.labels.title");
  const totalAcknowledged = t("tables.labels.totalAcknowledged");
  const totalAssigned = t("tables.labels.totalAssigned");
  const waitingSince = t("tables.labels.waitingSince");
  const waitTime = t("tables.labels.waitTime");
  const pending = t("tables.labels.pending");

  // onesource
  const onesourceManufacturer = t("onesource.edit.manufacturerLabel");
  const onesourceItemNumber = t("onesource.edit.searchResults.itemNumber");
  const revisionInformation = t(
    "onesource.edit.searchResults.revisionInformation"
  );

  //verge
  const verge_policy_title = titleCase(
    t("documentReports.associatedStandards.document.title")
  );
  const verge_standards_title = t(
    "documentReports.associatedStandards.standards"
  );
  const verge_associated_since = t(
    "documentReports.associatedStandards.document.associatedSince"
  );
  const verge_last_standard_update = t(
    "documentReports.associatedStandards.standard.LastUpdate"
  );

  const defaultTable = [
    {
      property: "name",
      header: {
        label: title
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentLink]
      }
    },
    {
      property: effectiveDateProperty,
      header: {
        label: effective
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatDate],
        transforms: [wrapToDocumentLink]
      },
      // this is not part of reactabular, but needed to get the correct label from graphql
      graphqlLabelName: effectiveDateGraphqlLabel
    }
  ];

  const associatedStandardsReport = [
    {
      property: "name",
      header: {
        transforms: [sortable]
      },
      cell: {
        props: {
          label: verge_policy_title
        },
        transforms: [wrapToLatestDocumentLink],
        formatters: [formatDocumentNameWithLockIcon]
      },
      graphqlLabel: {
        labelName: "documentLabel",
        getLabelText: documentLabel =>
          titleCase(
            t("documentReports.associatedStandards.document.title", {
              documentLabel
            })
          )
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "epAssociationDocuments",
      header: {
        label: verge_standards_title
      },
      cell: {
        props: {
          label: verge_standards_title
        },
        transforms: [],
        formatters: [formatEpReportNameField(otherArgs.loadEPContent)]
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        bloc__name: "asc",
        "-bloc__name": "desc"
      }
    },
    {
      property: "epAssociationDocuments",
      header: {
        label: verge_associated_since,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: verge_associated_since
        },
        transforms: [],
        formatters: [formatEpReportAssociatedDateField]
      },
      sort: {
        default: "association_datetime",
        association_datetime: "asc",
        "-association_datetime": "desc"
      }
    },
    {
      property: "epAssociationDocuments",
      header: {
        label: verge_last_standard_update
      },
      cell: {
        props: {
          color: "nav.25",
          label: verge_last_standard_update
        },
        transforms: [],
        formatters: [formatEpReportLastEpUpdateDateField]
      }
    },
    {
      property: "approvalDate",
      header: {
        label: approved,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: approved
        },
        transforms: [],
        formatters: [formatDate]
      },
      graphqlLabelName: "approvedDateLabel",
      sort: {
        approval_date: "asc",
        "-approval_date": "desc"
      }
    }
  ];

  const approveTable = [
    {
      property: "name",
      header: {
        label: title
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToApproveOrViewDocumentLink(viewOnlyPks)],
        formatters: [formatDocumentNameWithLockIcon]
      }
    },
    {
      property: "category.name",
      header: {
        label: category
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel"
    },
    {
      property: "bloc.name",
      header: {
        label: applicability
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      }
    },
    {
      property: effectiveDateProperty,
      header: {
        label: effective
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatDate],
        transforms: [wrapToDocumentApproveLink]
      },
      // this is not part of reactabular, but needed to get the correct label from graphql
      graphqlLabelName: effectiveDateGraphqlLabel
    }
  ];

  const reviewTable = [
    {
      property: "name",
      header: {
        label: title
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentLink],
        formatters: [formatDocumentNameWithLockIcon]
      }
    },
    {
      property: "category.name",
      header: {
        label: category
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel"
    },
    {
      property: "bloc.name",
      header: {
        label: applicability
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      }
    },
    {
      property: "expirationDate",
      header: {
        label: expiration
      },
      cell: {
        props: {
          color: "nav.25",
          label: expiration
        },
        formatters: [formatDate],
        transforms: [validateDueDate, wrapToDocumentLink]
      },
      graphqlLabelName: "expirationDateLabel"
    }
  ];

  const revisedRecentHomePageTable = [
    {
      property: "name",
      header: {
        label: title
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentShowChangesLink],
        formatters: [formatDocumentNameWithLockIcon]
      }
    },
    {
      property: "category.name",
      header: {
        label: category
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel"
    },
    {
      property: "bloc.name",
      header: {
        label: applicability
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      }
    },
    {
      property: effectiveDateProperty,
      header: {
        label: effective
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatDate],
        transforms: [wrapToDocumentShowChangesLink]
      },
      // this is not part of reactabular, but needed to get the correct label from graphql
      graphqlLabelName: effectiveDateGraphqlLabel
    },
    {
      property: "revisedFromParentStatus",
      header: {
        label: revised
      },
      cell: {
        props: {
          color: "nav.25",
          label: revised
        },
        formatters: [titleCase]
      }
    }
  ];

  const revisedRecentTable = [
    {
      property: "name",
      props: {
        style: {
          width: "25%"
        }
      },
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentShowChangesLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel",
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "revisedFromParentStatus",
      header: {
        label: revised
      },
      cell: {
        props: {
          color: "nav.25",
          label: revised
        },
        formatters: [titleCase]
      }
    },
    {
      property: effectiveDateProperty,
      header: {
        label: effective,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatDate]
      },
      graphqlLabelName: effectiveDateGraphqlLabel,
      sort: {
        default: "-effective_date",
        effective_date: "asc",
        "-effective_date": "desc"
      }
    }
  ];

  const revisedRecentMobileTable = [
    {
      property: "name",
      props: {
        style: {
          width: "25%"
        }
      },
      header: {
        label: title
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentShowChangesLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel",
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      }
    },
    {
      property: "revisedFromParentStatus",
      header: {
        label: revised
      },
      cell: {
        props: {
          color: "nav.25",
          label: revised
        },
        formatters: [titleCase]
      }
    },
    {
      property: effectiveDateProperty,
      header: {
        label: effective
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatDate]
      },
      graphqlLabelName: effectiveDateGraphqlLabel
    }
  ];

  const expiringTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "preview",
      props: {
        color: "nav.25",
        style: {
          width: "20%"
        }
      },
      header: {
        label: preview
      },
      cell: {
        props: {
          label: preview
        },
        formatters: [formatDocumentNameWithAttachmentIcon]
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel",
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "author",
      header: {
        label: "author",
        transforms: [sortable]
      },
      cell: {
        props: {
          label: "author",
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatAuthor]
      },
      graphqlLabelName: "authorLabel",
      sort: {
        author: "asc",
        "-author": "desc"
      }
    },
    {
      property: "expirationDate",
      header: {
        label: expiration,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: expiration
        },
        formatters: [formatDate]
      },
      graphqlLabelName: "expirationDateLabel",
      sort: {
        default: "expiration_date",
        expiration_date: "asc",
        "-expiration_date": "desc"
      }
    }
  ];

  const expiringTemplatesTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToSystemTemplateReviewLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "preview",
      props: {
        color: "nav.25",
        style: {
          width: "20%"
        }
      },
      header: {
        label: preview
      },
      cell: {
        props: {
          label: preview
        },
        formatters: [formatDocumentNameWithAttachmentIcon]
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel",
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "author",
      header: {
        label: "author",
        transforms: [sortable]
      },
      cell: {
        props: {
          label: "author",
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatAuthor]
      },
      graphqlLabelName: "authorLabel",
      sort: {
        author: "asc",
        "-author": "desc"
      }
    },
    {
      property: "publishDate",
      header: {
        label: waitingSince,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: waitingSince
        },
        formatters: [formatDate]
      },
      sort: {
        default: "expiration_date",
        expiration_date: "asc",
        "-expiration_date": "desc"
      }
    }
  ];

  const expiringWithPendingTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "preview",
      props: {
        color: "nav.25",
        style: {
          width: "20%"
        }
      },
      header: {
        label: preview
      },
      cell: {
        props: {
          label: preview
        },
        formatters: [formatDocumentNameWithAttachmentIcon]
      }
    },
    {
      property: "hasPendingChild",
      props: {
        color: "nav.25",
        style: {
          width: "10%"
        }
      },
      header: {
        label: pending
      },
      cell: {
        props: {
          label: pending
        },
        formatters: [formatYesNo]
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel",
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "author",
      header: {
        label: "author",
        transforms: [sortable]
      },
      cell: {
        props: {
          label: "author",
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatAuthor]
      },
      graphqlLabelName: "authorLabel",
      sort: {
        author: "asc",
        "-author": "desc"
      }
    },
    {
      property: "expirationDate",
      header: {
        label: expiration,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: expiration
        },
        formatters: [formatDate]
      },
      graphqlLabelName: "expirationDateLabel",
      sort: {
        default: "expiration_date",
        expiration_date: "asc",
        "-expiration_date": "desc"
      }
    }
  ];

  const expiringWithBulkReviewTable = [
    {
      property: "action",
      header: {
        label: action,
        transforms: [
          addSelectAllCheckbox(
            otherArgs.selectAllCheckboxState,
            otherArgs.onSelectAllCheckboxClick
          )
        ]
      },
      props: {
        style: {
          width: "50px"
        }
      },
      cell: {
        props: {
          label: action
        },
        transforms: [
          wrapToActionCheckbox(
            otherArgs.onActionClick,
            otherArgs.selectedCheckboxPks,
            otherArgs.isCheckboxDisabled
          )
        ]
      }
    },
    ...expiringTable
  ];

  const expiringWithBulkReviewPendingTable = [
    {
      property: "action",
      header: {
        label: action,
        transforms: [
          addSelectAllCheckbox(
            otherArgs.selectAllCheckboxState,
            otherArgs.onSelectAllCheckboxClick
          )
        ]
      },
      props: {
        style: {
          width: "50px"
        }
      },
      cell: {
        props: {
          label: action
        },
        transforms: [
          wrapToActionCheckbox(
            otherArgs.onActionClick,
            otherArgs.selectedCheckboxPks,
            otherArgs.isCheckboxDisabled
          )
        ]
      }
    },
    ...expiringWithPendingTable
  ];

  const awaitingActivationAll = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToApproveOrViewDocumentLink(viewOnlyPks)],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: category,
          color: "nav.25"
        }
      },
      graphqlLabelName: "categoryLabel",
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "approvalWorkflow.currentStep.approvers",
      header: {
        label: neededApprovers
      },
      cell: {
        props: {
          label: neededApprovers
        },
        formatters: [formatApprovers]
      }
    },
    {
      property: "approvalWorkflow.currentStep.name",
      header: {
        label: stepDescription
      },
      cell: {
        props: {
          label: stepDescription
        }
      }
    },
    {
      property: "approvalWorkflow.currentStep.created",
      header: {
        label: waitTime,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: waitTime
        },
        formatters: [formatDateTimeDistance]
      },
      sort: {
        default: "-approval_flow__current_step__created",
        approval_flow__current_step__created: "asc",
        "-approval_flow__current_step__created": "desc"
      }
    },
    {
      property: "revisedFromParentStatus",
      header: {
        label: revised
      },
      cell: {
        props: {
          label: revised
        },
        formatters: [titleCase]
      }
    },
    {
      property: "effectiveDate",
      header: {
        label: effective,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: effective
        }
      },
      sort: {
        effective_date: "asc",
        "-effective_date": "desc"
      }
    }
  ];

  const awaitingActivationMine = [
    {
      property: "action",
      header: {
        label: action,
        transforms: [
          addSelectAllCheckbox(
            otherArgs.selectAllCheckboxState,
            otherArgs.onSelectAllCheckboxClick
          )
        ]
      },
      props: {
        style: {
          width: "50px"
        }
      },
      cell: {
        props: {
          label: action
        },
        transforms: [
          wrapToActionCheckbox(
            otherArgs.onActionClick,
            otherArgs.selectedCheckboxPks,
            otherArgs.isCheckboxDisabled
          )
        ]
      }
    },
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToApproveOrViewDocumentLink(viewOnlyPks)],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: category,
          color: "nav.25"
        },
        graphqlLabelName: "categoryLabel"
      },
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "approvalWorkflow.currentStep.approvers",
      header: {
        label: neededApprovers
      },
      cell: {
        props: {
          label: neededApprovers
        },
        formatters: [formatApprovers]
      }
    },
    {
      property: "approvalWorkflow.currentStep.name",
      header: {
        label: stepDescription
      },
      cell: {
        props: {
          label: stepDescription
        }
      }
    },
    {
      property: "approvalWorkflow.currentStep.created",
      header: {
        label: waitTime,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: waitTime
        },
        formatters: [formatDateTimeDistance]
      },
      sort: {
        default: "-approval_flow__current_step__created",
        approval_flow__current_step__created: "asc",
        "-approval_flow__current_step__created": "desc"
      }
    },
    {
      property: "revisedFromParentStatus",
      header: {
        label: revised
      },
      cell: {
        props: {
          label: revised
        },
        formatters: [titleCase]
      }
    },
    {
      property: "effectiveDate",
      header: {
        label: effective,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: effective
        }
      },
      sort: {
        effective_date: "asc",
        "-effective_date": "desc"
      }
    }
  ];

  const searchTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToLatestDocumentLinkFromSearchPage(otherArgs)],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        default: "name",
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "preview",
      header: {
        label: preview
      },
      cell: {
        props: {
          color: "nav.0",
          fontStyle: "italic",
          label: preview
        },
        formatters: [formatPreview],
        transforms: [wrapToLatestDocumentLinkFromSearchPage(otherArgs)]
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        },
        transforms: [wrapToLatestDocumentLinkFromSearchPage(otherArgs)]
      },
      graphqlLabelName: "categoryLabel",
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIconOrListApplicability]
      },
      sort: {
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "revisionDate",
      header: {
        label: revised,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: revised
        },
        formatters: [formatDate],
        transforms: [wrapToLatestDocumentLinkFromSearchPage(otherArgs)]
      },
      graphqlLabelName: "revisionDateLabel",
      sort: {
        revision_date: "asc",
        "-revision_date": "desc"
      }
    },
    {
      property: effectiveDateProperty,
      header: {
        label: effective,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatDate],
        transforms: [wrapToLatestDocumentLinkFromSearchPage(otherArgs)]
      },
      graphqlLabelName: effectiveDateGraphqlLabel,
      sort: {
        effective_date: "asc",
        "-effective_date": "desc"
      }
    },
    {
      property: "approvalDate",
      header: {
        label: approved,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: approved
        },
        formatters: [formatDate],
        transforms: [wrapToLatestDocumentLinkFromSearchPage(otherArgs)]
      },
      graphqlLabelName: "approvedDateLabel",
      sort: {
        approval_date: "asc",
        "-approval_date": "desc"
      }
    }
  ];

  const searchMobile = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title,
          pt: [3],
          pb: [2]
        },
        transforms: [wrapToLatestDocumentLinkFromSearchPage(otherArgs)],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      }
    },
    {
      property: "preview",
      header: {
        label: preview
      },
      cell: {
        props: {
          color: "nav.0",
          fontStyle: "italic",
          label: preview,
          py: [1]
        },
        formatters: [formatPreview],
        transforms: [wrapToLatestDocumentLinkFromSearchPage(otherArgs)]
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: category,
          pt: [2],
          pb: [3],
          transforms: [wrapToLatestDocumentLinkFromSearchPage(otherArgs)]
        }
      },
      graphqlLabelName: "categoryLabel"
    }
  ];

  const myPoliciesToAcknowledgeTable = [
    {
      property: "name",
      props: {
        style: {
          width: "50%"
        }
      },
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentOrLineLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        default: "_approved_document__name",
        _approved_document__name: "asc",
        "-_approved_document__name": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        default: "_approved_document__bloc__name",
        _approved_document__bloc__name: "asc",
        "-_approved_document__bloc__name": "desc"
      }
    },
    {
      property: effectiveDateProperty,
      header: {
        label: effective,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatDate]
      },
      graphqlLabelName: effectiveDateGraphqlLabel,
      sort: {
        default: "-_approved_document__effective_date",
        _approved_document__effective_date: "asc",
        "-_approved_document__effective_date": "desc"
      }
    },
    {
      property: "waitTime",
      header: {
        label: waitTime,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: waitTime
        }
      },
      sort: {
        default: "wait_time",
        wait_time: "asc",
        "-wait_time": "desc"
      }
    }
  ];

  const myPoliciesToAcknowledgeMobileTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentOrLineLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        default: "name",
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: effectiveDateProperty,
      header: {
        label: effective,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatDate]
      },
      graphqlLabelName: effectiveDateGraphqlLabel,
      sort: {
        default: "-effective_date",
        effective_date: "asc",
        "-effective_date": "desc"
      }
    }
  ];

  const myPoliciesToAcknowledgeHomePageTable = [
    {
      property: "name",
      header: {
        label: title
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentOrLineLink],
        formatters: [formatDocumentNameWithLockIcon]
      }
    },
    {
      property: "category.name",
      header: {
        label: category
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel"
    },
    {
      property: "bloc.name",
      header: {
        label: applicability
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        formatters: [formatApplicabilityWithHomeIcon]
      }
    },
    {
      property: effectiveDateProperty,
      header: {
        label: effective
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatDate],
        transforms: [wrapToDocumentOrLineLink]
      },
      // this is not part of reactabular, but needed to get the correct label from graphql
      graphqlLabelName: effectiveDateGraphqlLabel
    }
  ];

  const myAcknowledgmentHistoryTable = [
    {
      property: "document.name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentLineLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        default: "_approved_document__name",
        _approved_document__name: "asc",
        "-_approved_document__name": "desc"
      }
    },
    {
      property: "document.bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: applicability
        },
        transforms: [wrapToDocumentLineLink],
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        default: "_approved_document__bloc__name",
        _approved_document__bloc__name: "asc",
        "-_approved_document__bloc__name": "desc"
      }
    },
    {
      property: "waitTime",
      header: {
        label: waitTime,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: waitTime
        },
        transforms: [wrapToDocumentLineLink],
        formatters: [formatWaitTimeForLine]
      },
      sort: {
        default: "wait_time",
        wait_time: "asc",
        "-wait_time": "desc"
      }
    },
    {
      property: "dateAcknowledged",
      header: {
        label: dateAcknowledged,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: dateAcknowledged
        },
        transforms: [wrapToDocumentLineLink],
        formatters: [formatAcknowledgedDateForLine]
      },
      sort: {
        default: "date_acknowledged",
        date_acknowledged: "asc",
        "-date_acknowledged": "desc"
      }
    },
    {
      property: "totalAcknowledged",
      header: {
        label: totalAcknowledged,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: totalAcknowledged
        },
        transforms: [wrapToDocumentLineLink]
      },
      sort: {
        default: "total_acknowledged",
        total_acknowledged: "asc",
        "-total_acknowledged": "desc"
      }
    },
    {
      property: "totalAssigned",
      header: {
        label: totalAssigned,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: totalAssigned
        },
        transforms: [wrapToDocumentLineLink]
      },
      sort: {
        default: "total_assigned",
        total_assigned: "asc",
        "-total_assigned": "desc"
      }
    }
  ];

  const myAcknowledgmentForLineTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        default: "_approved_document__name",
        _approved_document__name: "asc",
        "-_approved_document__name": "desc"
      }
    },
    {
      property: "category.name",
      header: {
        label: category
      },
      graphqlLabelName: "categoryLabel",
      cell: {
        props: {
          color: "nav.25",
          label: category
        },
        transforms: [wrapToDocumentLink]
      }
    },
    {
      property: "status",
      header: {
        label: documentStatus
      },
      graphqlLabel: {
        labelName: "documentLabel",
        getLabelText: documentLabel =>
          t("tables.labels.documentStatus", { documentLabel })
      },
      cell: {
        props: {
          color: "nav.25",
          label: documentStatus
        },
        transforms: [wrapToDocumentLink],
        formatters: [formatStatus]
      }
    },
    {
      property: "dateActive",
      header: {
        label: approved,
        transforms: [sortable]
      },
      graphqlLabelName: "approvedDateLabel",
      cell: {
        props: {
          color: "nav.25",
          label: approved
        },
        formatters: [formatDateShort],
        transforms: [wrapToDocumentLink]
      },
      sort: {
        default: "approval_date",
        approval_date: "asc",
        "-approval_date": "desc"
      }
    },
    {
      property: "revised",
      header: {
        label: revised
      },
      cell: {
        props: {
          color: "nav.25",
          label: revised
        },
        transforms: [wrapToDocumentLink]
      }
    },
    {
      property: "dateAssigned",
      header: {
        label: dateAssigned,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: dateAssigned
        },
        formatters: [formatDateShort],
        transforms: [wrapToDocumentLink]
      },
      sort: {
        default: "date_assigned",
        date_assigned: "asc",
        "-date_assigned": "desc"
      }
    },
    {
      property: "dateAcknowledged",
      header: {
        label: dateRead,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: dateRead
        },
        transforms: [wrapToDocumentLink],
        formatters: [formatAcknowledgedDateForDocument]
      },
      sort: {
        default: "date_acknowledged",
        date_acknowledged: "asc",
        "-date_acknowledged": "desc"
      }
    },
    {
      property: "waitTime",
      header: {
        label: waitTime,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: waitTime
        },
        transforms: [wrapToDocumentLink],
        formatters: [formatWaitTimeForDocument]
      },
      sort: {
        default: "wait_time",
        wait_time: "asc",
        "-wait_time": "desc"
      }
    }
  ];

  const draftsTable = [
    {
      property: "action",
      header: {
        label: actions
      },
      cell: {
        props: {
          label: actions,
          CellSpan: Text
        },
        formatters: [editLink, deleteButton],
        transforms: [frozen]
      }
    },
    {
      property: "savedCopy.name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToDocumentSaveLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        default: "name",
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "savedCopy.category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      graphqlLabelName: "categoryLabel",
      cell: {
        props: {
          label: category,
          CellSpan: Text,
          color: "nav.25"
        }
      },
      sort: {
        default: "category",
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "savedCopy.bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: applicability,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        default: "bloc",
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "isEditDraft",
      header: {
        label: draftType,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: draftType,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatDraftType]
      },
      sort: {
        document: "asc",
        "-document": "desc"
      }
    },
    {
      property: "saverDisplay",
      header: {
        label: draftSaver,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: draftSaver,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatDraftSaver]
      },
      sort: {
        saver: "asc",
        "-saver": "desc"
      }
    },
    {
      property: "createdDate",
      header: {
        label: draftCreated,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: draftCreated,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatDate]
      },
      sort: {
        default: "created_date",
        created_date: "asc",
        "-created_date": "desc"
      }
    },
    {
      property: "modifiedDate",
      header: {
        label: draftModified,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: draftModified,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatDate]
      },
      sort: {
        default: "last_modified",
        last_modified: "asc",
        "-last_modified": "desc"
      }
    }
  ];

  const lippincottTable = [
    {
      property: "name",
      props: {
        style: {
          width: "30%"
        }
      },
      header: {
        label: name
      },
      cell: {
        props: {
          label: name
        },
        transforms: [wrapToLippincottDocumentLink]
      }
    },
    {
      property: "synopsis",
      header: {
        label: preview
      },
      cell: {
        props: {
          label: preview
        },
        transforms: [wrapToLippincottSearchResult]
      }
    }
  ];

  const documentsRequiringAcceptanceTable = [
    {
      property: "action",
      header: {
        label: action
      },
      cell: {
        props: {
          label: action,
          CellSpan: Text
        },
        transforms: [frozen, wrapAcceptPolicyCheckbox(otherArgs.onActionClick)]
      }
    },
    {
      property: "document.name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToAcceptImportLink]
      },
      sort: {
        default: "name",
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "documentImport.importFile",
      header: {
        label: filename
      },
      cell: {
        props: {
          label: filename,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatImportFile]
      }
    },
    {
      property: "document.bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: applicability,
          CellSpan: Text,
          color: "nav.25"
        }
      },
      sort: {
        default: "bloc",
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "document.category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      graphqlLabelName: "categoryLabel",
      cell: {
        props: {
          label: category,
          CellSpan: Text,
          color: "nav.25"
        }
      },
      sort: {
        default: "category",
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "document.author",
      header: {
        label: "author",
        transforms: [sortable]
      },
      cell: {
        props: {
          label: "author",
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatAuthor]
      },
      graphqlLabelName: "authorLabel",
      sort: {
        default: "author",
        author: "asc",
        "-author": "desc"
      }
    },
    {
      property: "document.expirationDate",
      header: {
        label: expiration,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: expiration,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatDateShortNoDay]
      },
      sort: {
        default: "expiration_date",
        expiration_date: "asc",
        "-expiration_date": "desc"
      }
    }
  ];

  const scheduledDocumentsTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [frozen, wrapToDocumentLink],
        formatters: [
          formatDocumentNameWithAttachmentIcon,
          formatDocumentNameWithLockIcon
        ]
      },
      sort: {
        default: "name",
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      graphqlLabelName: "categoryLabel",
      cell: {
        props: {
          label: category,
          CellSpan: Text,
          color: "nav.25"
        }
      },
      sort: {
        default: "category",
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "bloc.name",
      header: {
        label: applicability,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: applicability,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatApplicabilityWithHomeIcon]
      },
      sort: {
        default: "bloc",
        bloc: "asc",
        "-bloc": "desc"
      }
    },
    {
      property: "effectiveDate",
      header: {
        label: effective,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: effective,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatDateShort]
      },
      sort: {
        default: "effective_date",
        expiration_date: "asc",
        "-effective_date": "desc"
      }
    }
  ];

  const documentRevisionsTable = [
    {
      property: "action",
      header: {
        label: action
      },
      cell: {
        props: {
          label: action
        },
        transforms: [frozen, wrapToEditLinkRevision]
      }
    },
    {
      property: "name",
      header: {
        label: title
      },
      cell: {
        props: {
          label: title,
          CellSpan: Text
        },
        transforms: [wrapToDocumentShowChangesLink]
      }
    },
    {
      property: "categoryName",
      header: {
        label: category
      },
      graphqlLabelName: "categoryLabel",
      cell: {
        props: {
          label: category,
          CellSpan: Text,
          color: "nav.25"
        }
      }
    },
    {
      property: "status",
      header: {
        label: documentStatus
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: documentStatus
        },
        formatters: [formatStatus]
      },
      graphqlLabel: {
        labelName: "documentLabel",
        getLabelText: documentLabel =>
          t("tables.labels.documentStatus", { documentLabel })
      }
    },
    {
      property: "approvalDate",
      header: {
        label: approved
      },
      cell: {
        props: {
          label: approved,
          CellSpan: Text,
          color: "nav.25"
        }
      }
    },
    {
      property: "revisedFromParentStatus",
      header: {
        label: revised
      },
      cell: {
        props: {
          label: revised,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatStatus]
      }
    }
  ];

  const documentRevisionsTableWithSED = [
    ...documentRevisionsTable.slice(0, 5),
    {
      property: "effectiveDate",
      header: {
        label: effective
      },
      cell: {
        props: {
          label: effective,
          CellSpan: Text,
          color: "nav.25"
        }
      },
      graphqlLabelName: effectiveDateGraphqlLabel
    },
    documentRevisionsTable[5]
  ];

  const blocsManagmentTable = [
    {
      property: "name",
      header: {
        label: name,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: name,
          fontSize: 1
        },
        transforms: [wrapToBlocLink]
      },
      sort: {
        default: "name",
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "tenants",
      header: {
        label: sites
      },
      cell: {
        props: {
          label: sites,
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatBlocSites]
      }
    },
    {
      property: "isEditable",
      header: {
        label: action
      },
      cell: {
        props: {
          label: action,
          CellSpan: Text
        },
        formatters: [formatBlocEditIcon]
      }
    }
  ];

  const blocDetailsTable = [
    {
      property: "name",
      header: {
        label: name,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: name,
          CellSpan: Text,
          color: "nav.25",
          fontSize: 1
        }
      },
      sort: {
        default: "name",
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "subdomain",
      header: {
        label: subdomain,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: subdomain,
          CellSpan: Text,
          color: "nav.25",
          fontSize: 1
        }
      },
      sort: {
        default: "subdomain",
        subdomain: "asc",
        "-subdomain": "desc"
      }
    },
    {
      property: "created",
      header: {
        label: created,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: created,
          CellSpan: Text,
          color: "nav.25",
          fontSize: 1
        },
        formatters: [formatDateShort]
      },
      sort: {
        default: "created",
        created: "asc",
        "-created": "desc"
      }
    }
  ];

  const blocTenantsTable = [
    {
      property: "action",
      header: {
        label: action,
        transforms: [
          addSelectAllCheckbox(
            otherArgs.selectAllCheckboxState,
            otherArgs.onSelectAllCheckboxClick
          )
        ]
      },
      cell: {
        props: {
          label: action,
          CellSpan: Text
        },
        transforms: [
          wrapToActionCheckbox(
            otherArgs.onActionClick,
            otherArgs.selectedCheckboxPks
          )
        ]
      }
    },
    {
      property: "name",
      header: {
        label: name,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: name,
          CellSpan: Text,
          color: "nav.25",
          fontSize: 1
        }
      },
      sort: {
        default: "name",
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "subdomain",
      header: {
        label: subdomain,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: subdomain,
          CellSpan: Text,
          color: "nav.25",
          fontSize: 1
        }
      },
      sort: {
        default: "subdomain",
        subdomain: "asc",
        "-subdomain": "desc"
      }
    },
    {
      property: "created",
      header: {
        label: created,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: created,
          CellSpan: Text,
          color: "nav.25",
          fontSize: 1
        },
        formatters: [formatDateShort]
      },
      sort: {
        default: "created",
        created: "asc",
        "-created": "desc"
      }
    }
  ];

  const duplicatePoliciesTable = [
    {
      property: "name",
      props: {
        style: {
          width: "60%"
        }
      },
      header: {
        label: title
      },
      cell: {
        props: {
          label: title
        }
      }
    },
    {
      property: "count",
      header: {
        label: numberDetected
      },
      cell: {
        props: {
          label: numberDetected
        }
      }
    },
    {
      property: "url",
      props: {
        style: {}
      },
      header: {
        label: action
      },
      cell: {
        props: {
          label: action
        },
        transforms: [wrapToViewDuplicatesLink],
        formatters: [formatViewDuplicates]
      }
    }
  ];

  const manageTemplatesTable = [
    {
      property: "name",
      header: {
        label: title
        // transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToSystemTemplateLink]
      },
      sort: {
        default: "name",
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "category.name",
      props: {
        style: {}
      },
      header: {
        label: category
        // transforms: [sortable]
      },
      graphqlLabelName: "categoryLabel",
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: category
        }
      },
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "author",
      header: {
        label: "author"
        // transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: "author"
        },
        formatters: [formatAuthor]
      },
      graphqlLabelName: "authorLabel",
      sort: {
        author: "asc",
        "-author": "desc"
      }
    },
    {
      property: "revisionDate",
      header: {
        label: revised
        // transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: revised
        },
        formatters: [formatDate]
      },
      graphqlLabelName: "revisionDateLabel",
      sort: {
        revision_date: "asc",
        "-revision_date": "desc"
      }
    },
    {
      property: "approvalDate",
      header: {
        label: approved
        // transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: approved
        },
        formatters: [formatDate]
      },
      graphqlLabelName: "approvedDateLabel",
      sort: {
        approved_date: "asc",
        "-approved_date": "desc"
      }
    },
    {
      property: "tags",
      header: {
        label: ""
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: ""
        },
        formatters: [formatTags]
      },
      graphqlLabelName: "referencesLabelPlural"
    }
  ];
  const manageTemplatesWithCheckboxesTable = [
    {
      property: "action",
      header: {
        label: action,
        transforms: [
          addSelectAllCheckbox(
            otherArgs.selectAllCheckboxState,
            otherArgs.onSelectAllCheckboxClick
          )
        ]
      },
      cell: {
        props: {
          label: action,
          CellSpan: Text
        },
        transforms: [
          wrapToActionCheckbox(
            otherArgs.onActionClick,
            otherArgs.selectedCheckboxPks
          )
        ]
      }
    },
    ...manageTemplatesTable
  ];
  const manageTemplatesTenantsTable = [
    {
      property: "action",
      header: {
        label: action,
        transforms: [
          addSelectAllCheckbox(
            otherArgs.selectAllCheckboxState,
            otherArgs.onSelectAllCheckboxClick
          )
        ]
      },
      cell: {
        props: {
          label: action,
          CellSpan: Text
        },
        transforms: [
          wrapToActionCheckbox(
            otherArgs.onActionClick,
            otherArgs.selectedCheckboxPks
          )
        ]
      }
    },
    {
      property: "name",
      header: {
        label: name,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: name,
          CellSpan: Text,
          color: "nav.25",
          fontSize: 1
        }
      },
      sort: {
        default: "tenants__name",
        tenants__name: "asc",
        "-tenants__name": "desc"
      }
    },
    {
      property: "subdomain",
      header: {
        label: subdomain,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: subdomain,
          CellSpan: Text,
          color: "nav.25",
          fontSize: 1
        }
      },
      sort: {
        default: "tenants__subdomain",
        tenants__subdomain: "asc",
        "-tenants__subdomain": "desc"
      }
    },
    {
      property: "created",
      header: {
        label: created,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: created,
          CellSpan: Text,
          color: "nav.25",
          fontSize: 1
        },
        formatters: [formatDateShort]
      },
      sort: {
        default: "tenants__created",
        tenants__created: "asc",
        "-tenants__created": "desc"
      }
    }
  ];
  const publishedTemplatesTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToSystemTemplateLink]
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "adopted",
      header: {
        transforms: [sortable]
      },
      graphqlLabel: {
        labelName: "documentLabelPlural",
        getLabelText: documentLabelPlural =>
          t("systemDocumentTemplates.published.locallyAdopted", {
            documentLabelPlural
          })
      },
      cell: {
        props: {
          label: title
        },
        formatters: [adoptedDocumentsCounters]
      },
      sort: {
        adopted: "asc",
        "-adopted": "desc"
      }
    },
    {
      property: "action",
      header: {
        label: action
      },
      cell: {
        props: {
          label: action
        },
        formatters: [viewAdoptedTemplateActions(true)]
      }
    }
  ];
  const unpublishedTemplatesTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToSystemTemplateLink]
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "adopted",
      header: {
        transforms: [sortable]
      },
      graphqlLabel: {
        labelName: "documentLabelPlural",
        getLabelText: documentLabelPlural =>
          t("systemDocumentTemplates.published.locallyAdopted", {
            documentLabelPlural
          })
      },
      cell: {
        props: {
          label: title
        }
      },
      sort: {
        adopted: "asc",
        "-adopted": "desc"
      }
    },
    {
      property: "action",
      header: {
        label: action
      },
      cell: {
        props: {
          label: action
        },
        formatters: [viewAdoptedTemplateActions(false)]
      }
    }
  ];
  const localDocumentsForTemplateTable = [
    {
      property: "systemDocumentTemplate.name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [
          wrapToDocumentLinkIfDocument(
            rowData => rowData.document,
            rowData => rowData.document
          )
        ],
        formatters: [formatDocumentNameWithAttachmentIcon]
      },
      sort: {
        system_document_template__name: "asc",
        "-system_document_template__name": "desc"
      }
    },
    {
      property: "tenantName",
      header: {
        label: siteName,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: siteName
        }
      },
      sort: {
        tenant: "asc",
        "-tenant": "desc"
      }
    },
    {
      property: "document.category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        },
        formatters: [formatValueOrEmpty]
      },
      graphqlLabelName: "categoryLabel",
      sort: {
        category: "asc",
        "-category": "desc"
      }
    },
    {
      property: "document.status",
      header: {
        label: status,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: status
        },
        formatters: [formatStatus, formatValueOrEmpty]
      },
      sort: {
        doc_status: "asc",
        "-doc_status": "desc"
      }
    },
    {
      property: `document.${effectiveDateProperty}`,
      header: {
        label: effective,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: effective
        },
        formatters: [formatValueOrEmpty, formatDate]
      },
      sort: {
        effective_date: "asc",
        "-effective_date": "desc"
      },
      graphqlLabelName: effectiveDateGraphqlLabel
    },
    {
      property: "document.author",
      header: {
        label: "author",
        transforms: [sortable]
      },
      cell: {
        props: {
          label: "author",
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatTemplateAdoptionAuthor, formatValueOrEmpty]
      },
      graphqlLabelName: "authorLabel",
      sort: {
        author: "asc",
        "-author": "desc"
      }
    },
    {
      property: "publishDate",
      header: {
        label: waitingSince,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: waitingSince
        },
        formatters: [formatValueOrEmpty, formatDate]
      },
      sort: {
        publish_date: "asc",
        "-publish_date": "desc"
      }
    }
  ];
  const unpublishedDocumentsForTemplateTable = [
    {
      property: "name",
      header: {
        label: title,
        transforms: [sortable]
      },
      cell: {
        props: {
          label: title
        },
        transforms: [wrapToLink],
        formatters: [formatDocumentNameWithAttachmentIcon]
      },
      sort: {
        name: "asc",
        "-name": "desc"
      }
    },
    {
      property: "tenantName",
      header: {
        label: siteName,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: siteName
        }
      },
      sort: {
        bloc__name: "asc",
        "-bloc__name": "desc"
      }
    },
    {
      property: "category.name",
      header: {
        label: category,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: category
        }
      },
      graphqlLabelName: "categoryLabel",
      sort: {
        category__name: "asc",
        "-category__name": "desc"
      }
    },
    {
      property: "status",
      header: {
        label: status,
        transforms: [sortable]
      },
      cell: {
        props: {
          color: "nav.25",
          label: status
        },
        formatters: [formatStatus]
      },
      sort: {
        status: "asc",
        "-status": "desc"
      }
    },
    {
      property: "author",
      header: {
        label: "author",
        transforms: [sortable]
      },
      cell: {
        props: {
          label: "author",
          CellSpan: Text,
          color: "nav.25"
        },
        formatters: [formatAuthor]
      },
      graphqlLabelName: "authorLabel",
      sort: {
        author: "asc",
        "-author": "desc"
      }
    }
  ];

  const oneSourceColumns = [
    {
      property: "manufacturerName",
      header: {
        label: onesourceManufacturer,
        props: {
          color: "nav.25"
        }
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: onesourceManufacturer,
          borderLeft: 0,
          borderTop: 0,
          style: {
            "vertical-align": "middle"
          }
        }
      }
    },
    {
      property: "catalogNumber",
      header: {
        label: onesourceItemNumber,
        props: {
          color: "nav.25"
        }
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: onesourceItemNumber,
          borderLeft: 0,
          borderTop: 0,
          style: {
            "vertical-align": "middle"
          }
        }
      }
    },
    {
      property: "revisionInformation",
      header: {
        label: revisionInformation,
        props: {
          color: "nav.25"
        }
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: revisionInformation,
          borderLeft: 0,
          borderTop: 0,
          style: {
            "vertical-align": "middle"
          }
        },
        formatters: [formatValueOrEmpty]
      }
    }
  ];

  const oneSourceSearchTable = [
    {
      property: "action",
      header: {
        label: actions,
        props: {
          color: "nav.25"
        }
      },
      cell: {
        props: {
          label: actions,
          CellSpan: Text,
          borderLeft: 0,
          borderTop: 0,
          style: {
            width: "150px",
            "vertical-align": "middle"
          },
          py: 2
        },
        transforms: [onesourceResultsActions(otherArgs.onClickAdd)]
      }
    },
    ...oneSourceColumns
  ];

  const oneSourceDocumentsTable = [
    {
      property: "action",
      header: {
        label: actions,
        props: {
          color: "nav.25"
        }
      },
      cell: {
        props: {
          label: actions,
          CellSpan: Text,
          borderLeft: 0,
          borderTop: 0,
          style: {
            width: "200px",
            "vertical-align": "middle"
          },
          py: 2
        },
        transforms: [onesourceDocumentsActions(otherArgs.onClickRemove)]
      }
    },
    ...oneSourceColumns
  ];

  const adminMenuCategoriesTable = [
    {
      property: "name",
      header: {
        label: category
      },
      cell: {
        props: {
          // CellSpan: Text,
          label: category
        },
        transforms: [wrapToCategorySearchResultsLink]
      },
      graphqlLabelName: "categoryLabel"
    },
    {
      property: "count",
      header: {
        label: count
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: count
        }
      }
    }
  ];

  const adminMenuWhitelistTable = [
    {
      property: "description",
      header: {
        label: name
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: name
        }
      }
    },
    {
      property: "ipRangeStart",
      header: {
        label: ipRangeStart
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: ipRangeStart
        }
      }
    },
    {
      property: "ipRangeEnd",
      header: {
        label: ipRangeEnd
      },
      cell: {
        props: {
          color: "nav.25",
          CellSpan: Text,
          label: ipRangeEnd
        }
      }
    }
  ];

  const presets = {
    associated_standards_report: associatedStandardsReport,
    admin_menu_categories: adminMenuCategoriesTable,
    admin_menu_whitelist: adminMenuWhitelistTable,
    awaiting_activation_all: awaitingActivationAll,
    awaiting_activation_mine: awaitingActivationMine,
    bloc_details: blocDetailsTable,
    blocs_management: blocsManagmentTable,
    bloc_tenants: blocTenantsTable,
    default: defaultTable,
    approve: approveTable,
    review: reviewTable,
    revised_recent_home_page: revisedRecentHomePageTable,
    revised_recent: revisedRecentTable,
    revised_recent_mobile: revisedRecentMobileTable,
    expiring_with_bulk_review: expiringWithBulkReviewTable,
    expiring_with_bulk_review_pending: expiringWithBulkReviewPendingTable,
    expiring: expiringTable,
    expiring_templates: expiringTemplatesTable,
    expiring_with_pending: expiringWithPendingTable,
    search: searchTable,
    search_mobile: searchMobile,
    my_policies_to_acknowledge: myPoliciesToAcknowledgeTable,
    my_policies_to_acknowledge_mobile: myPoliciesToAcknowledgeMobileTable,
    my_policies_to_acknowledge_home_page: myPoliciesToAcknowledgeHomePageTable,
    my_acknowledgment_history: myAcknowledgmentHistoryTable,
    my_acknowledgments_for_line: myAcknowledgmentForLineTable,
    drafts: draftsTable,
    lippincott: lippincottTable,
    documentsRequiringAcceptance: documentsRequiringAcceptanceTable,
    documentRevisions: documentRevisionsTable,
    documentRevisionsWithSED: documentRevisionsTableWithSED,
    scheduledDocuments: scheduledDocumentsTable,
    duplicatePolicies: duplicatePoliciesTable,
    manageTemplates: manageTemplatesTable,
    manageTemplatesWithCheckboxes: manageTemplatesWithCheckboxesTable,
    manageTemplatesTenants: manageTemplatesTenantsTable,
    onesourceSearch: oneSourceSearchTable,
    onesourceDocuments: oneSourceDocumentsTable,
    publishedTemplatesTable: publishedTemplatesTable,
    unpublishedTemplatesTable: unpublishedTemplatesTable,
    localDocumentsForTemplate: localDocumentsForTemplateTable,
    unpublishedDocumentsForTemplate: unpublishedDocumentsForTemplateTable
  };

  Object.keys(presets).forEach(key => {
    presets[key] = getColumnSortIndexes(
      removeApplicabilityColumnIfNeeded(presets[key])
    );
  });

  return presets;
};
